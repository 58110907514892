import { handleStartNewMessage } from '../../slices/inboxSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, createSearchParams } from 'react-router-dom'
import filterOptions from '../data/filter-options.json'
import { useState, useEffect, useRef } from 'react'

// Components
import { Header, Icon } from '@thryvlabs/maverick'
import { InboxMenuSearchBar } from '../inbox-menu-search-bar'
import { ComposeButton, StyledInboxDropdownButton } from './buttons'
import { setEditorView } from '../../slices/messageComposeSlice'
import { setInboxFilter } from '../../slices/inboxThreadsSlice'
import { setSelectedThread } from '../../slices/inboxThreadsSlice'
import { useInboxMenuConversations } from '../hooks/use-inbox-menu-conversation/use-inbox-menu-conversations'
import { useLazyQuery } from '@apollo/client'
import { FETCH_THREADS } from '../../../../graphql'
import {
  clearInboxThreadIds,
  setPinnedInboxThreadIds,
} from '../../slices/inboxThreadsSlice'
export const InboxMenuHeader = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { inboxFilter, bulkEditBtnClicked, bulkSelectedThreadIds } = useSelector(
    (state) => state.inboxThreads,
  )
  const { search } = useSelector((state) => state.inboxSearch)
  const { channelInfo } = useSelector((state) => state.connectChannels)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const onComposeClick = () => {
    dispatch(setEditorView('closed'))
    dispatch(handleStartNewMessage(true))
    dispatch(
      setSelectedThread({
        id: '',
        name: '',
      }),
    )
    let pathToNavigateTo = '/inbox'
    navigate({
      pathname: pathToNavigateTo,
      search: createSearchParams({ search: search }).toString(),
    })
  }

  const dropdownRef = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropdownRef])

  const { createVariables, initMessageThreads, initPinnedMessageThreads } =
    useInboxMenuConversations()

  const [getThreads, { data: threadsData }] = useLazyQuery(FETCH_THREADS, {
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    variables: createVariables(),
    onCompleted: () => {
      const threads = threadsData?.queryThreads.items
      initMessageThreads(threads)
      const pinned = threadsData?.pinned?.items
      initPinnedMessageThreads(pinned)
    },
  })

  const BulkEditSelectedCount = () => {
    if (bulkEditBtnClicked) {
      return (
        <div className="flex gap-2.5 items-center mb-[2px]">
          <Header fontWeight="semibold" variant="h3">
            {bulkSelectedThreadIds.length} Selected
          </Header>
        </div>
      )
    } else {
      return null
    }
  }

  return (
    <div className="px-5 py-5 md:px-4 md:pb-3">
      <div className="flex justify-between mb-4 md:hidden">
        <div ref={dropdownRef}>
          <BulkEditSelectedCount />
          <StyledInboxDropdownButton
            className={`${bulkEditBtnClicked ? 'hidden' : 'flex'}`}
            variant="secondary"
            buttonType="dropdown"
            items={filterOptions}
            setSelectedItem={(item) => {
              dispatch(setInboxFilter(item))
              dispatch(clearInboxThreadIds([]))
              dispatch(setPinnedInboxThreadIds([]))
              setTimeout(() => {
                getThreads()
              }, 1000)
              setDropdownOpen(false)
            }}
          >
            <div
              className="flex gap-2.5 items-center"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              <Header fontWeight="semibold" variant="h3">
                {inboxFilter === 'Inbox' ? 'Inbox' : inboxFilter}
              </Header>

              <Icon
                variant="caretDown"
                type="solid"
                width="10"
                fill="#4D4D4D"
                className={`${dropdownOpen && '-rotate-90'} transition duration-300`}
              />
            </div>
          </StyledInboxDropdownButton>
        </div>
        {bulkEditBtnClicked ? null : (
          <ComposeButton
            disabled={channelInfo?.length === 0}
            onClick={onComposeClick}
            id="composeButton"
          >
            Compose
          </ComposeButton>
        )}
      </div>

      <InboxMenuSearchBar />
    </div>
  )
}
