import { useIsRightRailMobile } from '../../../hooks/useIsRightRailMobile'
import { scrollToMsgInThread } from '../../../../inbox/global-search-section/sections/utils/scrollToMsgInThread'

// Components
import { Oval } from 'react-loader-spinner'
import SharedPhotoButtons from './SharedPhotoButtons'

const Container = ({ className, children }) => {
  const isRightRailMobile = useIsRightRailMobile()

  return (
    <div
      className={`${className} group relative bg-thryv-gray-light-200 border border-thryv-gray-light-300 rounded-lg`}
      style={{
        width: isRightRailMobile ? '100px' : '132px',
        height: isRightRailMobile ? '100px' : '132px',
      }}
    >
      {children}
    </div>
  )
}

export const InteractiveImage = ({
  className,
  variant = 'interactive', // OPTIONS: interactive, numeric
  isLoading = true,
  image = { filesk: '', src: '', alt: '', expand: () => {}, download: () => {} },
  handleSeeAllClick,
  children,
}) => {
  return (
    <Container className={`${className} flex-[]`}>
      {/* THIS IS THE BLACK OVERLAY ON HOVER */}
      {!isLoading && (
        <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-40 transition-opacity duration-200 z-10 rounded-lg"></div>
      )}

      {/* THE ACTUAL IMAGE */}
      <div className="absolute flex items-center justify-center w-full h-full top-0 left-0 overflow-hidden rounded-lg p-[5px]">
        <img
          src={image.src}
          alt={image.alt}
          className={`object-contain w-full h-full transition-all duration-500 ${
            isLoading ? 'opacity-0' : 'opacity-100'
          }`}
          onClick={image.expand}
        />
      </div>

      {/* THE INTERACTIVE BUTTONS */}
      {variant === 'interactive' && !isLoading && (
        <div className="absolute inset-0 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-30">
          <SharedPhotoButtons
            onViewPhotoClick={image.expand}
            onImportFileClick={() => scrollToMsgInThread(image.filesk)}
            onDownloadPhotoClick={image.download}
          />
        </div>
      )}

      {/* THE NUMERIC AMOUNT OF ADDITIONAL IMAGES */}
      {variant === 'numeric' && !isLoading && (
        <button
          type="button"
          aria-label="View all images"
          className="absolute inset-0 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-30 rounded-lg overflow-hidden"
          onClick={handleSeeAllClick}
        >
          <p className="text-white">{children}</p>
        </button>
      )}

      {/* LOADING ICON */}
      <div
        className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-all duration-500 ${
          isLoading ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <Oval
          height={25}
          width={25}
          color="#FF5000"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#E9E9E9"
          strokeWidth={5}
          strokeWidthSecondary={5}
        />
      </div>
    </Container>
  )
}
