import { ModalWrapper } from '@thryvlabs/maverick'
import FreemiumStaffUpdate from './FreemiumStaffUpdate'
import { useEffect, useState } from 'react'

const StaffSeatChangeModal = ({ planName, loading }) => {
  const [showModal, setShowModal] = useState(true)

  useEffect(() => {
    let seenStaffUpdateModal = localStorage.getItem('seenStaffUpdateModal')
    setShowModal(!seenStaffUpdateModal)
  }, [])

  const handleCloseStaffChangeModal = () => {
    localStorage.setItem('seenStaffUpdateModal', true)
    setShowModal(false)
  }

  if (loading) {
    return null
  }

  if (planName === 'Command Center Basic') {
    return (
      <ModalWrapper
        show={showModal}
        close={handleCloseStaffChangeModal}
        disableBackdropClick={false}
      >
        <FreemiumStaffUpdate
          handleCloseStaffChangeModal={handleCloseStaffChangeModal}
        />
      </ModalWrapper>
    )
  } else {
    return null
  }
}

export default StaffSeatChangeModal
