import useSharedMediaProps from '../hooks/use-shared-media-props'
import { transformAndSortAttachments } from '../utils/transform-and-sort-attachments'

// Components
import { SharedMediaHeader } from '../components/SharedMediaHeader'
import { AttachmentsList } from '../components/AttachmentsList'

const AllSharedFiles = ({ className, show, togglePreviewsPanel }) => {
  const { allSharedFilesProps } = useSharedMediaProps()

  const attachmentsByDates = allSharedFilesProps.groupedFileAttachments
  const attachmentsByDateDesc = transformAndSortAttachments(
    attachmentsByDates,
    'desc',
  )

  return (
    show && (
      <div className={`${className}`}>
        <SharedMediaHeader
          className="mb-[24px]"
          button={{
            text: 'Go Back',
            onClick: () => togglePreviewsPanel(),
          }}
        >
          Shared Files
        </SharedMediaHeader>

        <AttachmentsList attachmentsByDates={attachmentsByDateDesc} />
      </div>
    )
  )
}

export default AllSharedFiles
