import { useEffect, useState } from 'react'

const TabButton = ({ isActive, children, ...rest }) => {
  return (
    <button
      className={`w-[93px] h-[44px] mx-5 transition-colors duration-300 border-b-2 
        ${isActive ? 'border-[#FF5000FF]' : 'border-[#FF500000]'}`}
      {...rest}
    >
      <p
        className={`${
          isActive ? 'text-[#231F20]' : 'text-[#808080]'
        } transition-colors duration-300`}
      >
        {children}
      </p>
    </button>
  )
}

const HorizontalLine = () => (
  <div className="relative flex items-center">
    <div className="-m-7 flex-grow border-t border-[#CCCCCC]"></div>
  </div>
)

export const AttachmentsNavigation = ({ className, onTabChange }) => {
  // SELECTIONS: media, files
  const [activeTab, setActiveTab] = useState('media')

  useEffect(() => {
    onTabChange(activeTab)
  }, [activeTab])

  return (
    <div className={className}>
      <nav className="font-montserrat font-semibold text-sm ">
        <TabButton
          onClick={() => setActiveTab('media')}
          isActive={activeTab === 'media'}
          aria-label="Show Media Content"
        >
          Media
        </TabButton>

        <TabButton
          onClick={() => setActiveTab('files')}
          isActive={activeTab === 'files'}
          aria-label="Show Files Content"
        >
          Files
        </TabButton>
      </nav>
      <HorizontalLine />
    </div>
  )
}
