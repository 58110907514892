import { Header } from '@thryvlabs/maverick'

export const SharedMediaHeader = ({
  className,
  children,
  button = { onClick: () => {}, text: '' },
  disableButton = false,
  ...rest
}) => {
  return (
    <div className={`${className} flex justify-between`} {...rest}>
      <Header fontWeight="semibold" variant="h7">
        {children}
      </Header>

      {!disableButton && (
        <button onClick={button.onClick}>
          <p
            className="text-[#057AFF] text-[14px] font-open-sans leading-[20px] font-semibold"
            fontWeight="semibold"
          >
            {button.text}
          </p>
        </button>
      )}
    </div>
  )
}
