import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { GET_ACTIVE_VIDEO_ROOM } from '../../../../graphql/queries/vonage-queries'
import { useSelector } from 'react-redux'
import { selectedThreadSelector } from '../../../inbox/slices/inboxThreads.selectors'
import { useDispatch } from 'react-redux'
import { setRoomData } from '../../../../redux-toolkit'

export const useVideoRoomData = () => {
  const dispatch = useDispatch()
  const { selectedThreadId } = useSelector(selectedThreadSelector)

  const [isVideoCallActive, setIsVideoCallActive] = useState(false)

  const { data: videoRoomData } = useQuery(GET_ACTIVE_VIDEO_ROOM, {
    fetchPolicy: 'cache-and-network',
    variables: { threadId: selectedThreadId },
  })

  const initVideoRoom = () => {
    const activeVideoRoom = videoRoomData?.getActiveVideoRoomByThreadId
    if (activeVideoRoom) {
      setIsVideoCallActive(true)
      dispatch(
        setRoomData({ roomData: activeVideoRoom, threadId: selectedThreadId }),
      )
    }
  }

  useEffect(() => {
    initVideoRoom()
  }, [videoRoomData])

  return {
    isVideoCallActive,
  }
}
