import { createSlice } from '@reduxjs/toolkit'

const meetingsSlice = createSlice({
  name: 'meetings',
  initialState: {
    room: {
      room_data: null,
      loading: false,
      error: null,
    },
    session: {
      session_data: null,
      loading: false,
      error: null,
    },
    minutesRemaining: null,
    meetingEnded: false,
    vonage_room_info: {},
    threadId: null,
  },
  reducers: {
    clearVonageSession: (state) => {
      state.room = { room_data: null, loading: false, error: null }
      state.session = { session_data: null, loading: false, error: null }
      state.meetingEnded = true
    },
    setRoomInfo(state, action) {
      state.vonage_room_info = action.payload
    },
    setRoomData: (state, action) => {
      state.room.room_data = action.payload.roomData
      state.threadId = action.payload.threadId // Update the state with the new key
    },
    setMinutesRemaining(state, action) {
      state.minutesRemaining = action.payload
    },
  },
})

export const { clearVonageSession, setRoomInfo, setRoomData, setMinutesRemaining } =
  meetingsSlice.actions
export default meetingsSlice.reducer
