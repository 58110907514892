import { Icon } from '@thryvlabs/maverick'
import { TooltipWithCenteredArrow } from '../../../../common/tooltip-with-centered-arrow/tooltip-with-centered-arrow'

// Adds blue circle hover effect to photo icon buttons
const PhotoIconButton = ({ children, tooltipText, onClick, ...rest }) => {
  return (
    <TooltipWithCenteredArrow
      variant="top"
      title={tooltipText}
      width="max-content"
      visible={true}
      disableVisibleOnClick
    >
      <button
        type="button"
        className="h-[30px] w-[30px] flex items-center justify-center transition ease-in-out hover:bg-[#057AFF] rounded-full"
        onClick={onClick}
        {...rest}
      >
        {children}
      </button>
    </TooltipWithCenteredArrow>
  )
}

const SharedPhotoButtons = ({
  onViewPhotoClick,
  onImportFileClick,
  onDownloadPhotoClick,
}) => {
  return (
    <>
      <PhotoIconButton
        tooltipText="Preview"
        onClick={onViewPhotoClick}
        data-testid="view-photo"
      >
        <Icon
          variant="eye"
          type="regular"
          height="18px"
          width="18px"
          color="#FFFFFF"
        />
      </PhotoIconButton>

      <PhotoIconButton
        tooltipText="Go to file"
        onClick={onImportFileClick}
        data-testid="import-photo"
      >
        <Icon
          variant="fileImport"
          type="regular"
          height="18px"
          width="18px"
          color="#FFFFFF"
        />
      </PhotoIconButton>

      <PhotoIconButton
        tooltipText="Download"
        onClick={onDownloadPhotoClick}
        data-testid="download-photo"
      >
        <Icon
          variant="download"
          type="regular"
          height="18px"
          width="18px"
          color="#FFFFFF"
        />
      </PhotoIconButton>
    </>
  )
}

export default SharedPhotoButtons
