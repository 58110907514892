import useSharedMediaProps from '../hooks/use-shared-media-props'
import { sortImages } from '../utils/sort-images'

// Components
import { SharedMediaHeader } from '../components/SharedMediaHeader'
import { PhotosList } from '../components/PhotosList'

const AllSharedPhotos = ({ className, show, togglePreviewsPanel }) => {
  const { allSharedPhotosProps } = useSharedMediaProps()

  const imagesByDates = allSharedPhotosProps.groupedAttachments
  const imagesByDateDesc = sortImages(imagesByDates, 'desc')

  return (
    show && (
      <section className={className}>
        <SharedMediaHeader
          className="mb-[24px]"
          button={{ text: 'Go Back', onClick: togglePreviewsPanel }}
        >
          Shared Photos
        </SharedMediaHeader>

        <PhotosList imagesByDate={imagesByDateDesc} />
      </section>
    )
  )
}

export default AllSharedPhotos
