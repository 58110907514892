import { Avatar } from '@thryvlabs/maverick'
import avatarColorPicker from '../utils/avatarColorPicker'

const NON_CONTACTS = [
  'noreply',
  'no-reply',
  'notifications',
  'reply',
  'support',
  'marketing',
  'info',
  'contactus',
  'contact-us',
]

const useAvatarComponent = (
  state = {
    email: '',
    contactName: '',
    picture_url: '',
    avatarSize: 'large',
  },
) => {
  const { avatarSize, email, contactName, picture_url } = state
  let fullName

  if (contactName?.includes('@')) {
    fullName = contactName?.split('@')[0]
  }
  const isNum = /^\d+$/.test(contactName)

  let firstName, lastName

  if (contactName?.length && !contactName?.includes('@')) {
    firstName = contactName?.split(' ')[0]
    lastName = contactName?.split(' ')[1]
  }

  if (fullName?.length && fullName?.includes('.')) {
    firstName = fullName?.split('.')[0]
    lastName = fullName?.split('.')[1]
  }

  const RenderUserProfile = ({ size }) => {
    const renderImageAvatar = picture_url?.length ? (
      <Avatar variant="image" imageUrl={picture_url} size={size || avatarSize} />
    ) : null

    const renderNameAvatar =
      firstName?.length &&
      !isNum &&
      !picture_url?.length &&
      !NON_CONTACTS.includes(firstName) &&
      firstName !== 'Unknown' ? (
        <Avatar
          variant="name"
          size={size || 'large'}
          name={{ firstName, lastName } || null}
          staticBackground
          backgroundColor={avatarColorPicker(lastName?.at(0))}
        />
      ) : null

    const renderDefaultAvatar =
      (!email?.length && !firstName?.length && !picture_url?.length) ||
      isNum ||
      firstName === 'Unknown' ? (
        <Avatar variant="unidentified" size={size || 'large'} />
      ) : null

    return (
      <div className="w-full flex justify-center items-start mx-auto">
        {renderImageAvatar}
        {renderNameAvatar}
        {renderDefaultAvatar}
      </div>
    )
  }

  return { RenderUserProfile }
}

export default useAvatarComponent
