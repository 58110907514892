export const addCountryCodeToPhone = (phoneNumber, countryCode) => {
  let phone_without_country_code_regex
  let phone_with_country_code_regex

  if (countryCode === 'US' || countryCode === 'CA') {
    phone_without_country_code_regex = /^[\d{10}\s]*$/
    phone_with_country_code_regex = /^[\d{11}\s]*$/
    if (
      phoneNumber?.length === 10 &&
      phone_without_country_code_regex.test(phoneNumber)
    ) {
      return `1${phoneNumber}`
    } else if (
      phoneNumber?.length === 11 &&
      phone_with_country_code_regex.test(phoneNumber)
    ) {
      return phoneNumber
    } else if (phoneNumber?.length === 0) {
      return phoneNumber
    } else {
      return 'error'
    }
  }

  if (countryCode === 'AU') {
    phone_without_country_code_regex = /^[\d{9,10}\s]*$/
    phone_with_country_code_regex = /^[\d{11,12}\s]*$/
    if (
      phoneNumber.length === 9 ||
      (phoneNumber.length === 10 &&
        phoneNumber[0] === '0' &&
        phone_without_country_code_regex.test(phoneNumber))
    ) {
      return `61${phoneNumber}`
    } else if (
      (phoneNumber.length === 11 || phoneNumber.length === 12) &&
      phone_with_country_code_regex.test(phoneNumber) &&
      phoneNumber[0] === '6' &&
      phoneNumber[1] === '1'
    ) {
      return phoneNumber
    } else if (phoneNumber.length === 0) {
      return phoneNumber
    } else {
      return 'error'
    }
  }

  if (countryCode === 'NZ') {
    phone_without_country_code_regex = /^[\d{8,10}\s]*$/
    phone_with_country_code_regex = /^[\d{9,10}\s]*$/
    if (
      phoneNumber.length === 8 ||
      (phoneNumber.length === 10 &&
        phoneNumber[0] === '0' &&
        phone_without_country_code_regex.test(phoneNumber))
    ) {
      return `64${phoneNumber}`
    } else if (
      (phoneNumber.length === 9 || phoneNumber.length === 10) &&
      phone_with_country_code_regex.test(phoneNumber) &&
      phoneNumber[0] === '6' &&
      phoneNumber[1] === '4'
    ) {
      return phoneNumber
    } else if (phoneNumber.length === 0) {
      return phoneNumber
    } else {
      return 'error'
    }
  }
}
