// This function checks if items such as the image viewer are clicked, it won't auto close the side panel.
// Feel free to add more exclusions here.
const areExcludedItemsClicked = (e) => {
  const closeAttachmentsButtonClicked = e
    .composedPath()
    .includes(document.getElementById('cc-file-selected'))

  const closeProfileButtonClicked = e
    .composedPath()
    .includes(document.getElementById('cc-profile-selected'))

  const imageViewerClicked = e
    .composedPath()
    .includes(document.getElementsByClassName('fslightbox-container')[0])

  if (
    closeProfileButtonClicked ||
    closeAttachmentsButtonClicked ||
    imageViewerClicked
  ) {
    return true
  }
  return false
}

export { areExcludedItemsClicked }
