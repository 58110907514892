import { useSelector, useDispatch } from 'react-redux'
import {
  setInboxThreadIds,
  setPinnedInboxThreadIds,
  setInboxThreadSk1s,
  setPinnedInboxThreadSk1s,
  setSelectedThread,
} from '../../../slices/inboxThreadsSlice'
import { DATE_THREADS_SORTS } from '../../utils/date-threads-sorts'
import { DATE_THREADS_FILTERS } from '../../utils/date-threads-filters'
import {
  midnight,
  yesterdayMidnightTime,
  startOfLastWeekMidnightTime,
  startOfLastMonthMidnightTime,
  startOfLastYearMidnightTime,
} from '../../helper-functions/getTimeThreads'
import { setPinnedThreads, setTimeThreads } from '../../../slices/inboxSlice'
import { ascSort, descSort } from './utils'

export const useInboxMenuConversations = () => {
  const dispatch = useDispatch()

  const { inboxSort, inboxFilter, inboxAdvancedFilters } = useSelector(
    (state) => state.inboxThreads,
  )
  const selectedThread = useSelector((state) => state.inboxThreads.selectedThread)

  const dateRange = inboxAdvancedFilters.date.type

  const sortProperties =
    DATE_THREADS_SORTS[inboxSort || 'Date received: newest first']
  const filter = DATE_THREADS_FILTERS[inboxFilter]

  const filterThreadIds = (threads) =>
    threads.filter((t) => t.messages.items.length > 0).map((t) => t.thread_id)

  const filterThreadSk1AndId = (threads) =>
    threads
      .filter((t) => t.messages.items.length > 0)
      .map((x) => ({ id: x.thread_id, sk1: x.sk1 }))

  const sortThreadsAsc = (
    fieldType,
    allThreads,
    overOneYear,
    lastYear,
    lastMonth,
    lastWeek,
    yesterday,
    today,
  ) => {
    dispatch(
      setTimeThreads({
        todayMessageThreads:
          fieldType === 'thread_name'
            ? ascSort(fieldType, allThreads)
            : ascSort(fieldType, overOneYear),
        yesterdayMessageThreads: ascSort(fieldType, lastYear),
        lastWeekMessageThreads: ascSort(fieldType, lastMonth),
        lastMonthMessageThreads: ascSort(fieldType, lastWeek),
        lastYearMessageThreads: ascSort(fieldType, yesterday),
        overOneYearMessageThreads: ascSort(fieldType, today),
      }),
    )
  }

  const sortThreadsDesc = (
    fieldType,
    allThreads,
    today,
    yesterday,
    lastWeek,
    lastMonth,
    lastYear,
    overOneYear,
  ) => {
    dispatch(
      setTimeThreads({
        todayMessageThreads:
          fieldType === 'thread_name'
            ? descSort(fieldType, allThreads)
            : descSort(fieldType, today),
        yesterdayMessageThreads: descSort(fieldType, yesterday),
        lastWeekMessageThreads: descSort(fieldType, lastWeek),
        lastMonthMessageThreads: descSort(fieldType, lastMonth),
        lastYearMessageThreads: descSort(fieldType, lastYear),
        overOneYearMessageThreads: descSort(fieldType, overOneYear),
      }),
    )
  }

  const populateThreads = (threads) => {
    const today = []
    const yesterday = []
    const lastWeek = []
    const lastMonth = []
    const lastYear = []
    const overOneYear = []

    threads.forEach((thread) => {
      const compareTime = thread?.last_message_time
      const latestChannel = thread.messages.items[0]?.chan_type

      const isIncluded =
        inboxAdvancedFilters.channels.includes(latestChannel) ||
        inboxAdvancedFilters.channels.length === 0

      const newThread = { ...thread, compareTime: compareTime }

      const createDate = {
        isToday: compareTime > midnight,
        isYesterday: yesterdayMidnightTime < compareTime && compareTime < midnight,
        isLastWeek:
          startOfLastWeekMidnightTime < compareTime &&
          compareTime < yesterdayMidnightTime,
        isLastMonth:
          startOfLastMonthMidnightTime < compareTime &&
          compareTime < startOfLastWeekMidnightTime,
        isLastYear:
          startOfLastYearMidnightTime < compareTime &&
          compareTime < startOfLastMonthMidnightTime,
        isOverOneYear: compareTime < startOfLastYearMidnightTime,
      }

      if (createDate?.isToday && isIncluded) today.push(newThread)
      else if (createDate?.isYesterday && isIncluded) yesterday.push(newThread)
      else if (createDate?.isLastWeek && isIncluded) lastWeek.push(newThread)
      else if (createDate?.isLastMonth && isIncluded) lastMonth.push(newThread)
      else if (createDate?.isLastYear && isIncluded) lastYear.push(newThread)
      else if (createDate?.isOverOneYear && isIncluded) overOneYear.push(newThread)

      if (
        selectedThread.id === thread.thread_id &&
        selectedThread.name !== thread.thread_name
      ) {
        dispatch(
          setSelectedThread({ id: thread.thread_id, name: thread.thread_name }),
        )
      }
    })

    return {
      today,
      yesterday,
      lastWeek,
      lastMonth,
      lastYear,
      overOneYear,
    }
  }

  const initMessageThreads = (threads) => {
    const { today, yesterday, lastWeek, lastMonth, lastYear, overOneYear } =
      populateThreads(threads)

    // Filter mappings
    const filterMapping = {
      today: [...today],
      yesterday: [...today, ...yesterday],
      lastWeek: [...today, ...yesterday, ...lastWeek],
      lastMonth: [...today, ...yesterday, ...lastWeek, ...lastMonth],
      lastYear: [...today, ...yesterday, ...lastWeek, ...lastMonth, ...lastYear],
      overOneYear: [
        ...today,
        ...yesterday,
        ...lastWeek,
        ...lastMonth,
        ...lastYear,
        ...overOneYear,
      ],
    }
    // console.log('in real', populateThreads(threads))

    switch (dateRange) {
      case 'Any':
        dispatch(setInboxThreadIds(filterThreadIds(threads)))
        dispatch(setInboxThreadSk1s(filterThreadSk1AndId(threads)))
        break
      case 'Yesterday':
        dispatch(setInboxThreadIds(filterThreadIds(filterMapping.yesterday)))
        dispatch(setInboxThreadSk1s(filterThreadSk1AndId(filterMapping.yesterday)))
        break
      case 'Past week':
        dispatch(setInboxThreadIds(filterThreadIds(filterMapping.lastWeek)))
        dispatch(setInboxThreadSk1s(filterThreadSk1AndId(filterMapping.lastWeek)))
        break
      case 'Past month':
        dispatch(setInboxThreadIds(filterThreadIds(filterMapping.lastMonth)))
        dispatch(setInboxThreadSk1s(filterThreadSk1AndId(filterMapping.lastMonth)))
        break
      case 'Past year':
        dispatch(setInboxThreadIds(filterThreadIds(filterMapping.lastYear)))
        dispatch(setInboxThreadSk1s(filterThreadSk1AndId(filterMapping.lastYear)))
        break
      default:
        break
    }

    const sort_order = sortProperties?.sort_order
    const sort_field = sortProperties?.field

    const allThreads = [
      ...today,
      ...yesterday,
      ...lastWeek,
      ...lastMonth,
      ...lastYear,
      ...overOneYear,
    ]

    switch (sort_order) {
      case 'asc':
        if (sort_field === 'message_time')
          sortThreadsAsc(
            sort_field,
            allThreads,
            overOneYear,
            lastYear,
            lastMonth,
            lastWeek,
            yesterday,
            today,
          )
        else if (sort_field === 'thread_name')
          sortThreadsAsc(
            sort_field,
            allThreads,
            overOneYear,
            lastYear,
            lastMonth,
            lastWeek,
            yesterday,
            today,
          )
        break
      case 'desc':
        if (sort_field === 'message_time')
          sortThreadsDesc(
            sort_field,
            allThreads,
            today,
            yesterday,
            lastWeek,
            lastMonth,
            lastYear,
            overOneYear,
          )
        else if (sort_field === 'thread_name')
          sortThreadsDesc(
            sort_field,
            allThreads,
            today,
            yesterday,
            lastWeek,
            lastMonth,
            lastYear,
            overOneYear,
          )
        break
      default:
        break
    }
  }

  const filterPinnedThreadIds = (pinnedThreads) =>
    pinnedThreads.filter((t) => t.messages.items.length > 0).map((t) => t.thread_id)

  const filterPinnedThreadSk1AndId = (threads) =>
    threads
      .filter((t) => t.messages.items.length > 0)
      .map((x) => ({ id: x.thread_id, sk1: x.sk1 }))

  const populatePinnedThreads = (pinnedThreads, inboxFilter) => {
    const pinned = []

    pinnedThreads.forEach((thread) => {
      const compareTime = thread?.last_message_time
      const newThread = { ...thread, compareTime }
      pinned.push(newThread)
    })

    switch (inboxFilter) {
      case 'Inbox':
      case 'Unread':
      case 'Completed':
        dispatch(setPinnedInboxThreadIds(filterPinnedThreadIds(pinned)))
        dispatch(setPinnedInboxThreadSk1s(filterPinnedThreadSk1AndId(pinned)))
        break
      default:
        break
    }

    return pinned
  }

  const sortPinnedThreadsAsc = (fieldType, pinned) => {
    dispatch(setPinnedThreads(ascSort(fieldType, pinned)))
  }

  const sortPinnedThreadsDesc = (fieldType, pinned) => {
    dispatch(setPinnedThreads(descSort(fieldType, pinned)))
  }

  const initPinnedMessageThreads = (pinnedThreads) => {
    const pinned = populatePinnedThreads(pinnedThreads, inboxFilter)

    const sort_order = sortProperties?.sort_order
    const sort_field = sortProperties?.field

    switch (sort_order) {
      case 'asc':
        if (sort_field === 'message_time')
          sortPinnedThreadsAsc('message_time', pinned)
        else if (sort_field === 'thread_name')
          sortPinnedThreadsAsc('thread_name', pinned)
        break
      case 'desc':
        if (sort_field === 'message_time')
          sortPinnedThreadsDesc('message_time', pinned)
        else if (sort_field === 'thread_name')
          sortPinnedThreadsDesc('thread_name', pinned)
        break
      default:
        break
    }
  }

  const createVariables = (nextToken, limit = 200) => {
    return {
      folder: filter,
      limit: limit,
      order: sortProperties?.sort_order,
      sort: sortProperties?.field,
      nextToken: nextToken,
    }
  }

  const createPinnedVariables = (nextToken) => {
    return {
      folder: 'pinned',
      order: sortProperties?.sort_order,
      sort: sortProperties?.field,
      nextToken: nextToken,
    }
  }

  return {
    createVariables,
    initMessageThreads,
    initPinnedMessageThreads,
    createPinnedVariables,
    filterThreadIds,
    filterThreadSk1AndId,
    sortThreadsAsc,
    sortThreadsDesc,
    populateThreads,
    filterPinnedThreadIds,
    filterPinnedThreadSk1AndId,
    sortPinnedThreadsAsc,
    sortPinnedThreadsDesc,
    populatePinnedThreads,
  }
}
