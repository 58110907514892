import { useIsRightRailMobile } from '../../hooks/useIsRightRailMobile'
import { RxDownload } from 'react-icons/rx'

export const DateDownloadHeader = ({
  className,
  button = { onDownloadAllClick: () => {}, ariaLabel: '' },
  children,
}) => {
  const isRightRailMobile = useIsRightRailMobile()

  return (
    <div className={`${className} flex justify-between`}>
      <p className="font-normal font-open-sans text-[14px] text-[#231F20]">
        {children}
      </p>

      {!isRightRailMobile && (
        <button
          type="button"
          className="text-[14px] font-normal font-semibold font-open-sans text-[#A3A5A7] hover:text-[#057AFF] transition-all duration-300"
          onClick={button.onDownloadAllClick}
          aria-label={button.ariaLabel}
        >
          Download All
        </button>
      )}

      {isRightRailMobile && (
        <button
          type="button"
          className="flex gap-[5px] items-center justify-between"
          onClick={button.onDownloadAllClick}
          aria-label={button.ariaLabel}
        >
          <RxDownload size={20} color="#057AFF" />

          <p className="text-[14px] font-normal font-semibold font-open-sans text-[#057AFF]">
            Download All
          </p>
        </button>
      )}
    </div>
  )
}
