import { Toggle as MaverickToggle } from '@thryvlabs/maverick'
import { useSelector, useDispatch } from 'react-redux'
import { setDoNotDisturbPreferences } from '../../../redux-toolkit/slices/user-preferences/user-preferences'

const Toggle = ({
  toggleStatus,
  setToggleStatus,
  label,
  isToggleList = false,
  index,
  testid,
  labelTestid,
}) => {
  const dispatch = useDispatch()
  const { doNotDisturb } = useSelector(
    (state) => state.userPreferences.notificationPreferences,
  )
  const changeToggleStatus = () => {
    if (isToggleList) {
      setToggleStatus(index)
    } else {
      setToggleStatus(!toggleStatus)
    }
  }

  const renderDnd =
    label === 'Do Not Disturb' ? (
      <>
        <MaverickToggle
          data-testid={testid || label}
          toggleSize="small"
          onChange={() => {
            dispatch(setDoNotDisturbPreferences(!doNotDisturb))
          }}
          toggled={doNotDisturb}
          customSelectedColor="#16A085"
          customUnselectedColor="#808080"
        />
        <h6
          data-testid={labelTestid}
          className="ml-[6px] text-[14px] open-sans leading-[20px] text-[#231F20] font-primary"
        >
          {label}
        </h6>
      </>
    ) : null

  const renderOtherNotifications =
    label !== 'Do Not Disturb' ? (
      <>
        <MaverickToggle
          data-testid={testid || label}
          toggleSize="small"
          onChange={changeToggleStatus}
          toggled={toggleStatus}
          customSelectedColor="#16A085"
          customUnselectedColor="#808080"
        />
        <h6
          data-testid={labelTestid}
          className="ml-[6px] text-[14px] open-sans font-normal leading-[20px] text-[#231F20] font-primary"
        >
          {label}
        </h6>
      </>
    ) : null

  return (
    <div className=" flex items-center mb-[7] mt-[3px]">
      {renderDnd}
      {renderOtherNotifications}
    </div>
  )
}

export default Toggle
