import { useFlags } from 'launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import usePhoneChannel from '../../../../hooks/use-phone-channel'

export const useAvailableChannelsByThread = (channelInfo, recipient) => {
  const { toggleTenDlc } = useFlags()
  const { countryIso2 } = useSelector((state) => state.countryCode)
  const [phoneChannel] = usePhoneChannel()
  const brandStatus = useSelector(
    (state) => state.phoneRegistration.brand?.brand_status,
  )
  const campaignStatus = useSelector(
    (state) => state.phoneRegistration.brand?.campaign_status,
  )

  const activeChannels = channelInfo?.filter(
    (channel) => channel.Status === '1' && channel.Provider !== 'video',
  )

  // if (skip) {
  //   return { activeWithoutPhone: [], disabledWithoutPhone: [] }
  // }

  const isActive =
    toggleTenDlc ||
    countryIso2 === 'AU' ||
    countryIso2 === 'CA' ||
    countryIso2 === 'NZ' ||
    brandStatus === 'PENDING' ||
    brandStatus === 'ACTIVE' ||
    brandStatus === 'VERIFIED' ||
    brandStatus === 'UNVERIFIED' ||
    campaignStatus === 'UPDATES_REQUIRED' ||
    campaignStatus === 'PENDING_REVIEW' ||
    campaignStatus === 'ACTIVE' ||
    phoneChannel?.ChannelAccessLevel === '2'

  let channelsByThread = []
  let disabledChannelsByThread = []

  activeChannels.forEach((channel) => {
    if (
      (channel.VendorID === '1' &&
        recipient.email &&
        recipient.email !== 'Unallocated') ||
      recipient.email === 'Facebook Messenger' ||
      (channel.VendorID === '2' && recipient.phoneNumber) ||
      (channel.VendorID === '3' && recipient.email === 'Facebook Messenger') ||
      (channel.VendorID === '3' && recipient.email === 'Unallocated')
    ) {
      channelsByThread.push(channel)
    } else {
      disabledChannelsByThread.push(channel)
    }
  })

  const activeWithoutPhone = !isActive
    ? channelsByThread.filter(
        (x) => x.Provider !== 'phone' && x.ChannelAccessLevel === '2',
      )
    : channelsByThread

  const disabledWithoutPhone = !isActive
    ? disabledChannelsByThread.filter(
        (x) => x.Provider !== 'phone' && x.ChannelAccessLevel === '2',
      )
    : disabledChannelsByThread

  return {
    channelsByThread: activeWithoutPhone,
    disabledChannelsByThread: disabledWithoutPhone,
    isActive,
  }
}
