import { useState, useEffect } from 'react'
import { TooltipWithCenteredArrow } from '../../../../common/tooltip-with-centered-arrow'
import { ChannelsPopup } from './channel-popup'
import { useSelector } from 'react-redux'
import ChannelsIcon from '../../../../channels-icon/channels-icon'
import formatContactData from '../../../hooks/formatContactData'
import { useDispatch } from 'react-redux'
import { setSelectedChannel } from '../../../slices/messageComposeSlice'
import { useAvailableChannelsByThread } from '../../utils/useAvailableChannelByThread'

const Button = ({ isActive, active, ...rest }) => {
  const { selectedChannel } = useSelector((s) => s.messageCompose)

  const showIcon = () => {
    if (
      (selectedChannel?.Provider && selectedChannel?.Provider !== 'phone') ||
      (selectedChannel?.Provider &&
        selectedChannel?.Provider === 'phone' &&
        isActive)
    ) {
      return selectedChannel?.Provider
    }

    return 'towerBroadcast'
  }
  if (active) {
    return (
      <button
        data-testid="channel-button"
        type="button"
        className={`
          p-2 
          border-[1.5px] border-solid rounded-full border-thryv-gray-medium-500 hover:border-[#FF5000]
          transition-border duration-300
          ${active && ' border-[#FF5000]'}`}
        {...rest}
      >
        <ChannelsIcon
          variant={showIcon()}
          color={showIcon() === 'towerBroadcast' ? '#057AFF' : null}
          width={24}
          height={24}
        />
      </button>
    )
  } else {
    return (
      <TooltipWithCenteredArrow
        variant="top"
        width="max-content"
        title={
          selectedChannel?.Provider
            ? selectedChannel?.Provider === 'phone'
              ? formatContactData(selectedChannel?.AccountUserValue)
              : selectedChannel?.AccountUserValue
            : 'Explore Channels'
        }
        className="!max-w-[500px] !min-w-0"
        positioning={{ offsetX: '0px', offsetY: '-8px' }}
        disableVisibleOnClick={true}
      >
        <button
          data-testid="channel-button"
          type="button"
          className={`
          p-2 
          border-[1.5px] border-solid rounded-full border-thryv-gray-medium-500 hover:border-[#FF5000]
          transition-border duration-300
          ${active && ' border-[#FF5000]'}`}
          {...rest}
        >
          <ChannelsIcon
            variant={showIcon()}
            color={showIcon() === 'towerBroadcast' ? '#057AFF' : null}
            width={24}
            height={24}
          />
        </button>
      </TooltipWithCenteredArrow>
    )
  }
}

export const ChannelsButton = ({ lastMessage, draftMessageData }) => {
  const { channelInfo } = useSelector((state) => state.connectChannels) //using data coming from BE
  const { editorView, sendButtonClicked } = useSelector(
    (state) => state.messageCompose,
  )
  const { isLoading, recipient } = useSelector((state) => state.inbox)
  const dispatch = useDispatch()
  const createMessageError = useSelector((state) => state.inbox.error.status)

  const [active, setActive] = useState(false)
  const { channelsByThread, disabledChannelsByThread, isActive } =
    useAvailableChannelsByThread(channelInfo, recipient)

  const initSelectedChannel = () => {
    if (
      (channelInfo && editorView === 'closed') ||
      (channelInfo && draftMessageData)
    ) {
      // adding editorView to make sure the channel doesnt change as the user is typing and only does it when the editor is closed
      const lastChannel = channelInfo?.filter(
        (channel) => channel.ChannelID === lastMessage?.chanpk,
      )
      //this is in the case that is a new thread and doesnt have messages so we default to the first channel on the list
      if (lastChannel.length === 0) {
        dispatch(setSelectedChannel(channelsByThread[0]))
      } else {
        dispatch(setSelectedChannel(lastChannel[0]))
      }
    }
  }

  useEffect(() => {
    initSelectedChannel()
  }, [lastMessage, channelInfo])

  return (
    <div className="relative">
      <ChannelsPopup
        availableChannels={channelsByThread}
        disabledChannels={disabledChannelsByThread}
        className="bottom-full mb-2"
        show={active}
        setActive={setActive}
      />
      <Button
        disabled={(isLoading || sendButtonClicked) && !createMessageError}
        isActive={isActive}
        active={active}
        onClick={() => setActive(!active)}
      />
    </div>
  )
}
