import useSharedMediaProps from '../hooks/use-shared-media-props'
import { getFormattedFileSize } from '../utils/get-formatted-file-size'

// Components
import { SharedMediaHeader } from '../components/SharedMediaHeader'
import AttachmentCard from '../components/AttachmentCard'
import { Oval } from 'react-loader-spinner'

const LoadingCircle = () => (
  <div className="flex justify-center items-center h-full">
    <Oval color="##FF5000" height={55} width={55} />
  </div>
)

const SharedFilesPreview = ({ className, show, onSeeAllClick }) => {
  const { allSharedFilesProps, mediaProps } = useSharedMediaProps()
  const downloadAttachment = allSharedFilesProps.handleFileAttachmentDownload

  const isLoading = mediaProps.loading
  const allAttachments = allSharedFilesProps.attachmentFiles
  const visibleAttachments = allAttachments.slice(0, 8)
  const hasAttachments = Boolean(visibleAttachments.length)

  return (
    show && (
      <div className={`${className}`}>
        <section className={className}>
          <SharedMediaHeader
            className="mb-[24px]"
            button={{ text: 'See All', onClick: onSeeAllClick }}
            disableButton={!hasAttachments || allAttachments.length <= 8}
          >
            Shared Files
          </SharedMediaHeader>

          {isLoading && <LoadingCircle />}

          {!isLoading && hasAttachments && (
            <ul>
              {visibleAttachments.map((attachment, i) => {
                const parsedBody = JSON.parse(attachment.body)

                return (
                  <li key={i}>
                    <AttachmentCard
                      file={{
                        sk: attachment.filesk,
                        name: parsedBody.filename,
                        size: getFormattedFileSize(parsedBody.size),
                        type: parsedBody.filename.slice(
                          parsedBody.filename?.lastIndexOf('.') + 1,
                        ),
                        download: () => downloadAttachment(attachment),
                      }}
                    />
                  </li>
                )
              })}
            </ul>
          )}

          {!hasAttachments && (
            <p className="self-start font-open-sans font-normal text-sm text-[#808080]">
              Your shared files will appear here.
            </p>
          )}
        </section>
      </div>
    )
  )
}

export default SharedFilesPreview
