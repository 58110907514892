export const SaveButton = ({ className, onClick, enabled, ...rest }) => {
  return (
    <button
      aria-label="Save Contact Form"
      type="submit"
      onClick={onClick}
      className={`${className} ${!enabled && 'pointer-events-none'}`}
      {...rest}
    >
      <p
        className={`text-[14px] font-open-sans font-semibold transition-all duration-300 ${
          enabled ? 'text-[#057AFF]' : 'text-[#c4c4c4]'
        }`}
      >
        Save
      </p>
    </button>
  )
}
