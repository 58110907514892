// Components
import { ModalWrapper } from '@thryvlabs/maverick'
import UserProfileAndActivity from '../../user-profile-and-activity/UserProfileAndActivity'

export const MobileContactCardModal = ({ show, closeModal }) => {
  // "close" property needed in mobile wrapper to prevent it from breaking.
  return (
    <ModalWrapper show={show} close={() => {}}>
      <div className="min-h-[80vh] w-[75vw] max-w-[360px] flex flex-col">
        <UserProfileAndActivity
          isMobile={true}
          className="mt-[24px]"
          onCloseModalButtonClick={closeModal}
        />
      </div>
    </ModalWrapper>
  )
}
