import './clip-right-side.css'
import { CommandCenterIcon } from '../../command-center-icon'

export const ShowAttachmentsButton = ({ className, onClick, isActive }) => {
  const defaultStyles =
    'h-[40px] w-[40px] flex items-center justify-center rounded-l-full border-l-[1px] border-t-[1px] border-b-[1px] border-[#ECEEF1] transition-all duration-300'
  const activeStyles = 'bg-white shadow-[0px_2px_4px_0px_rgba(0,0,0,0.25)]'
  const inactiveStyles = 'bg-[#231F20] hover:bg-[#4D4D4D]'

  return (
    <button
      aria-label="Open current contact's files"
      id="cc-file-selected"
      className={`${className} 
        ${defaultStyles} ${isActive ? activeStyles : inactiveStyles}
        clip-right-side
      `}
      onClick={onClick}
    >
      <CommandCenterIcon
        type="regular"
        variant="paperClip"
        height="20px"
        width="15px"
        fill={isActive ? '#000000' : '#ffffff'}
      />
    </button>
  )
}
