import { useDispatch, useSelector } from 'react-redux'
import { setSelectedChannel } from '../../../slices/messageComposeSlice'
import formatContactData from '../../../hooks/formatContactData'
import ChannelsIcon from '../../../../channels-icon/channels-icon'
import { ParagraphText } from '@thryvlabs/maverick'

export const ChannelItem = ({
  className,
  textColor,
  channel,
  setActive,
  disabled = false,
}) => {
  const dispatch = useDispatch()
  const selectedChannel = useSelector(
    (state) => state.messageCompose.selectedChannel,
  )

  const handleSelectChannel = () => {
    dispatch(setSelectedChannel(channel))
    localStorage.setItem('selectedChannel', JSON.stringify(channel))
    setActive(false)
  }
  const checkIfSame = selectedChannel?.AccountUserValue === channel?.AccountUserValue

  return (
    <>
      <li
        className={`rounded ${
          checkIfSame && 'bg-thryv-gray-medium-100/100'
        } h-[36px] pb-1 relative mb-2`}
      >
        <button
          data-testid={`channel-change-${channel.Provider}`}
          onClick={() => handleSelectChannel()}
          className={`${className} flex p-2 gap-2 rounded  w-full ${
            channel.Status === '99'
              ? 'hover:border border-[#EF9595] bg-[#FCEAEA]'
              : 'enabled:hover:bg-thryv-gray-light-200'
          } `}
          disabled={channel.Status === '99' || disabled}
        >
          <ChannelsIcon
            variant={channel.Provider}
            width={20}
            height={20}
            className={channel.Status === 99 ? 'opacity-30' : null}
          />
          <ParagraphText
            variant="reg"
            color={textColor || '#231F20'}
            className={`${
              channel.Status === 99 || disabled ? 'opacity-30' : null
            } truncate`}
          >
            {channel?.Nickname
              ? channel.Nickname.replace(/''/g, "'")
              : channel.Provider === 'video'
                ? 'Video Channel'
                : formatContactData(channel.AccountUserValue)}
          </ParagraphText>
        </button>
      </li>
    </>
  )
}
