import { useSelector } from 'react-redux'

export const usePhoneChannel = () => {
  const { channelInfo } = useSelector((state) => state.connectChannels)

  if (Array.isArray(channelInfo)) {
    const phoneChannelIndex = channelInfo.findIndex(
      (channel) => channel?.Provider === 'phone',
    )
    return [
      phoneChannelIndex >= 0 ? channelInfo[phoneChannelIndex] : null,
      phoneChannelIndex,
    ]
  }

  return [null, -1]
}

export default usePhoneChannel
