import { useState } from 'react'

// Components
import { PhotosAndFilesPreviewsPanel } from './photos-and-files-previews-panel/PhotosAndFilesPreviewsPanel'
import AllSharedFiles from './files-panels/AllSharedFiles'
import AllSharedPhotos from './photos-panels/AllSharedPhotos'

// Convert epoch to readable date format
export const getEpochToFormattedDate = (epochTimestamp) => {
  const date = new Date(epochTimestamp)
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear().toString()
  return `${month}.${day}.${year}`
}

const SharedMedia = () => {
  // OPTIONS: previews-panel, all-photos-panel, all-files-panel
  const [selectedPanel, setSelectedPanel] = useState('previews-panel')

  return (
    <div>
      <PhotosAndFilesPreviewsPanel
        show={selectedPanel === 'previews-panel'}
        toggleShowAllFiles={() => setSelectedPanel('all-files-panel')}
        toggleShowAllPhotos={() => setSelectedPanel('all-photos-panel')}
      />

      <AllSharedPhotos
        show={selectedPanel === 'all-photos-panel'}
        togglePreviewsPanel={() => setSelectedPanel('previews-panel')}
      />

      <AllSharedFiles
        show={selectedPanel === 'all-files-panel'}
        togglePreviewsPanel={() => setSelectedPanel('previews-panel')}
      />
    </div>
  )
}

export default SharedMedia
