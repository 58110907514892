export const sortImages = (images, variant) => {
  let sortedImages = { ...images }

  if (variant === 'desc') {
    const entries = Object.entries(sortedImages)

    sortedImages = entries
      .sort((a, b) => {
        const dateA = new Date(a[0].split('.').reverse().join('-'))
        const dateB = new Date(b[0].split('.').reverse().join('-'))
        return dateB - dateA
      })
      .map(([date, attachments]) => ({ date, attachments }))
  }

  return sortedImages
}
