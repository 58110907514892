import * as yup from 'yup'
import commandCenterPhoneValidator from '../utils/commandCenterPhoneValidator'
import validator from 'validator'

export function getContactSchema(countryCode) {
  let max = 0
  const usernameErrorMessage = 'No Special Characters Allowed'

  if (countryCode === 'US' || countryCode === 'CA') max = 11
  if (countryCode === 'AU') max = 12
  if (countryCode === 'NZ') max = 10

  return yup.object({
    firstName: yup
      .string()
      .required('First Name is required')
      .max(15, 'First Name must be at most 15 characters')
      .trim()
      .test('not-email', 'Email addresses are not allowed', (value) => {
        const isEmail = validator.isEmail(value) // Return true if the value does not match the email pattern
        return !isEmail
      })
      .test('not-phone', 'Phone numbers are not allowed', (value) => {
        const isPhone = commandCenterPhoneValidator(value, countryCode).isValid()
        return !isPhone
      })
      .typeError(usernameErrorMessage),

    lastName: yup
      .string()
      .max(15, 'Last Name must be at most 15 characters')
      .trim()
      .test('not-email', 'Email addresses are not allowed', (value) => {
        const isEmail = validator.isEmail(value) // Return true if the value does not match the email pattern
        return !isEmail
      })
      .test('not-phone', 'Phone numbers are not allowed', (value) => {
        const isPhone = commandCenterPhoneValidator(value, countryCode).isValid()
        return !isPhone
      })
      .typeError(usernameErrorMessage),

    email: yup
      .string()
      .email()
      .test(
        'at-least-one',
        'Please enter either an email address or phone number, or both. At least one is required.',
        function () {
          return this.parent.phone.length || this.parent.email.length
        },
      ),

    phone: yup
      .string()
      .max(max)
      .matches(/^[\d\s]*$/, 'No special characters allowed')
      .typeError(
        `Please enter a valid ${countryCode} phone number without any special characters`,
      )
      .test(
        'at-least-one',
        'Please enter either an email address or phone number, or both. At least one is required.',
        function () {
          return this.parent.phone.length || this.parent.email.length
        },
      ),
  })
}
