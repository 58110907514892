import { createSlice } from '@reduxjs/toolkit'

// DEPRECATED - NO LONGER IN USE AND WE MAY NEED TO CLEAN THIS UP FROM OTHER FILES
export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    isAttachmentsModalOpen: false,
  },
  reducers: {
    toggleAttachmentsModal: (state, action) => {
      state.isAttachmentsModalOpen = action.payload ?? !state.isAttachmentsModalOpen
    },
  },
})

export const { toggleAttachmentsModal } = modalSlice.actions
export default modalSlice.reducer
