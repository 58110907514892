import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { LoadingSpinner } from '../components/common/loading-spinner'

const unauthenticatedStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  flexDirection: 'column',
}

const ThryvLeads = () => {
  const { user } = useAuth0()
  const thryvId = user.businessId

  const thryvLeadsApiKey = import.meta.env.VITE_THRYV_LEADS_API_KEY
  let tokenUrl = import.meta.env.VITE_THRYV_LEADS_TOKEN_URL
  let tokenAuth = import.meta.env.VITE_THRYV_LEADS_API_AUTH

  const [leadsUrl, setLeadsUrl] = useState(
    `${
      import.meta.env.VITE_THRYV_LEADS_IFRAME_URL
    }?thryvId=${thryvId}&isInternalUser=${import.meta.env.VITE_ENV !== 'prod'}`,
  )

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    const getThryvLeadsAuthToken = async () => {
      const res = await axios.post(
        tokenUrl,
        {
          additionalParams: { thryvId: thryvId },
          key: thryvLeadsApiKey,
          type: 'THRYVLEADS',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic ${tokenAuth}`,
          },
        },
      )
      if (res.data.merchantToken) {
        const token = res.data.merchantToken
        const leadsUrlWithToken = `${leadsUrl}&token=${token}`
        setLeadsUrl(leadsUrlWithToken)
        setLoading(false)
        setError(false)
      } else {
        setLoading(false)
        setError(true)
      }
    }
    getThryvLeadsAuthToken()
  }, [])

  if (error)
    return (
      <div style={unauthenticatedStyles}>
        <p>Thryv Leads failed to load.</p>
        <br />
        <p>Please contact Thryv Support at (844) 998-4798</p>
      </div>
    )

  if (loading)
    return (
      <div style={unauthenticatedStyles}>
        <LoadingSpinner />
      </div>
    )

  return (
    <div>
      <iframe
        style={{ height: 'calc(100vh - 66.18px' }}
        width="100%"
        src={leadsUrl}
      />
    </div>
  )
}
export default ThryvLeads
