export const shapeContactForSubmission = (unshapedContact, sk1) => {
  const shapedBody = {
    given_name: unshapedContact.firstName || '',
    surname: unshapedContact.lastName || '',
    emails: [{ email: unshapedContact.email, type: '' }],
    phone_numbers: [{ number: unshapedContact.phone, type: '' }],
  }

  let shapedContact = {
    body: JSON.stringify(shapedBody),
    contactsk2: unshapedContact.email?.toLowerCase() || '', // Email
    contactsk3: unshapedContact.phone || '', // Phone
    sk1: sk1,
  }

  // Verify Phone inputs
  if (unshapedContact.phone === 'error' || unshapedContact.phone === '') {
    shapedContact.contactsk3 = ''
  }

  // Verify Email inputs
  if (unshapedContact.email === 'error' || unshapedContact.email === '') {
    shapedContact.contactsk2 = ''
  }

  return shapedContact
}
