/* eslint-disable no-console */
import { parsePhoneNumber } from 'libphonenumber-js'
import phoneFormatter from '../../../utils/phoneFormatter'

const formatContactData = (data) => {
  if (/^\+?\d+$/.test(data)) {
    try {
      const ausNoCountryCodeRegex = /^\d{9,10}$/
      const auCountryCodeRegex = /^\+?\d{11,12}$/
      if (
        data.length === 9 ||
        (data.length === 10 && data[0] === '0' && ausNoCountryCodeRegex.test(data))
      ) {
        try {
          return '+61 ' + parsePhoneNumber(data, 'AU').formatNational()
        } catch (error) {
          return parsePhoneNumber(data, 'AU').formatNational()
        }
      } else if (
        data.length >= 11 &&
        data.length <= 13 &&
        auCountryCodeRegex.test(data) &&
        ((data[0] === '+' && data[1] === '6' && data[2] === '1') ||
          (data[0] === '6' && data[1] === '1'))
      ) {
        return '+61 ' + parsePhoneNumber(data, 'AU').formatNational()
      }

      if (phoneFormatter(data).includes('undefined')) {
        if (data.includes('+') && data[1] === '1') {
          return '+1 ' + parsePhoneNumber(data).formatNational()
        }
        if (data.includes('+')) {
          return parsePhoneNumber(data).formatInternational()
        }
      } else {
        return phoneFormatter(data)
      }
    } catch (error) {
      console.error(error)
      return data
    }
  } else {
    return data
  }
}

export default formatContactData
