import { useSelector } from 'react-redux'

export const getPhoneCard = (cardData) => {
  if (Array.isArray(cardData) && cardData.length) {
    const phoneCardIndex = cardData.findIndex((card) => card.iconName === 'calls')
    return [phoneCardIndex > -1 ? cardData[phoneCardIndex] : null, phoneCardIndex]
  }

  return [null, -1]
}

const useCardData = () => {
  const cardData = useSelector((state) => state.connectChannels.cardData)

  return {
    getPhoneCard: () => getPhoneCard(cardData),
    cardData,
  }
}

export default useCardData
