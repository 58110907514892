import { useState } from 'react'
import useSharedMediaProps from '../../shared-media/hooks/use-shared-media-props'
import { sortImages } from '../../shared-media/utils/sort-images'
import { transformAndSortAttachments } from '../../shared-media/utils/transform-and-sort-attachments'

// Components
import { AttachmentsNavigation } from './attachments-navigation/attachments-navigation'
import { Header, ModalWrapper } from '@thryvlabs/maverick'
import { CloseModalButton } from '../components/CloseModalButton'
import { AttachmentsList } from '../../shared-media/components/AttachmentsList'
import { PhotosList } from '../../shared-media/components/PhotosList'

const MobileSharedMediaModal = ({ show, handleCloseModal }) => {
  //SELECTIONS: media, files
  const [activeTab, setActiveTab] = useState('media')

  const { allSharedPhotosProps } = useSharedMediaProps()

  const imagesByDates = allSharedPhotosProps.groupedAttachments
  const imagesByDateDesc = sortImages(imagesByDates, 'desc')
  const hasImages = Boolean(imagesByDateDesc.length)

  const { allSharedFilesProps } = useSharedMediaProps()

  const attachmentsByDates = allSharedFilesProps.groupedFileAttachments
  const attachmentsByDateDesc = transformAndSortAttachments(
    attachmentsByDates,
    'desc',
  )
  const hasAttachments = Boolean(attachmentsByDateDesc.length)

  // IMPORTANT: "close" property needed in mobile wrapper to prevent it from breaking.
  return (
    <ModalWrapper show={show} close={() => {}}>
      <div className="min-h-[80vh] w-[75vw] max-w-[320px]">
        <CloseModalButton
          className="block mr-0 ml-auto"
          onClick={handleCloseModal}
          aria-label="Close Attachments Modal"
        />

        <Header variant="h6" fontWeight="semibold" className="mb-[20px] mt-[8px]">
          Attachments
        </Header>

        <AttachmentsNavigation
          className="mb-[24px]"
          onTabChange={(newActiveTab) => setActiveTab(newActiveTab)}
        />

        <div className="max-h-[78vh] overflow-auto">
          {activeTab === 'media' && (
            <>
              {hasImages && <PhotosList imagesByDate={imagesByDateDesc} />}

              {!hasImages && (
                <p className="self-start font-open-sans font-normal text-sm text-[#808080] my-5">
                  Your shared photos will appear here.
                </p>
              )}
            </>
          )}

          {activeTab === 'files' && (
            <>
              {hasAttachments && (
                <AttachmentsList attachmentsByDates={attachmentsByDateDesc} />
              )}

              {!hasAttachments && (
                <p className="self-start font-open-sans font-normal text-sm text-[#808080] my-5">
                  Your shared files will appear here.
                </p>
              )}
            </>
          )}
        </div>
      </div>
    </ModalWrapper>
  )
}

export default MobileSharedMediaModal
