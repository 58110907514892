import { useState } from 'react'
import 'react-awesome-lightbox/build/style.css'
import { useSelector } from 'react-redux/es/hooks/useSelector'
import { useEffect, forwardRef } from 'react'
import { useClickOutside } from '../../hooks'
import { areExcludedItemsClicked } from './utils/areExcludedItemsClicked'
import { useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

// Components
import RightPanelButtons from './right-panel-buttons/RightPanelButtons'
import SharedMedia from './shared-media/SharedMedia'
import UserProfileAndActivity from './user-profile-and-activity/UserProfileAndActivity'

const RightPanel = forwardRef(
  ({ setShowPanel, showPanel, openConnectPhoneModal }, ref) => {
    const panelDefaultStyles =
      'grow-0 relative bg-white font-semibold font-montserrat text-thryv-black-500 border-l-[1px] border-thryv-gray-light-400 no-scrollbar transition-all duration-300 z-30 shadow-[0px_2px_4px_0px_rgba(0,0,0,0.25)]'
    const activePanelStyles = '!w-[350px]'
    const inactivePanelStyles = 'w-[0px]'

    const { pathname, state: routerState } = useLocation()
    const isExtraLargeScreen = useMediaQuery({ minWidth: 1280 })

    const [showProfilePanel, setShowProfilePanel] = useState(false)
    const [showAttachmentsPanel, setShowAttachmentsPanel] = useState(false)

    const selectedThreadId = useSelector(
      (state) => state.inboxThreads.selectedThread.id,
    )

    const closeEverything = () => {
      setShowPanel(false)
      setShowAttachmentsPanel(false)
      setShowProfilePanel(false)
    }

    const toggleShowAttachmentsPanel = () => {
      if (showAttachmentsPanel) {
        closeEverything()
      } else {
        setShowPanel(true)
        setShowAttachmentsPanel(true)
        setShowProfilePanel(false)
      }
    }

    const toggleShowProfilePanel = () => {
      if (showProfilePanel) {
        closeEverything()
      } else {
        setShowPanel(true)
        setShowProfilePanel(true)
        setShowAttachmentsPanel(false)
      }
    }

    useEffect(() => {
      if (routerState?.openRightPanel === true) {
        setShowPanel(true)
        setShowProfilePanel(true)
        window.history.replaceState({}, document.title)
      }
    }, [routerState])

    useEffect(() => {
      if (pathname?.includes('global-search')) closeEverything()
    }, [pathname])

    useEffect(() => {
      closeEverything()
    }, [selectedThreadId])

    useEffect(() => {
      if (!isExtraLargeScreen) closeEverything()
    }, [isExtraLargeScreen])

    useClickOutside((e) => {
      const excludedItemsClicked = areExcludedItemsClicked(e)
      if (!excludedItemsClicked) closeEverything()
    }, ref)

    return (
      <div
        ref={ref}
        className={`
        ${panelDefaultStyles} 
        ${showPanel ? activePanelStyles : inactivePanelStyles}
      `}
      >
        {isExtraLargeScreen && (
          <RightPanelButtons
            className="absolute -left-[40px] inline-block top-[10rem]"
            onAttachmentsButtonClick={toggleShowAttachmentsPanel}
            onProfileButtonClick={toggleShowProfilePanel}
            defaultActiveButton={
              (showProfilePanel && 'profile') ||
              (showAttachmentsPanel && 'attachments') ||
              'none'
            }
            ref={ref}
          />
        )}

        <div className="p-[30px] h-0 min-h-full overflow-y-scroll flex flex-col">
          {/* LEFT POSITIONING BASED ON WIDTH OF THE RIGHT PANEL BUTTON */}

          {showPanel && showAttachmentsPanel && <SharedMedia />}
          {showPanel && showProfilePanel && (
            <UserProfileAndActivity openConnectPhoneModal={openConnectPhoneModal} />
          )}
        </div>
      </div>
    )
  },
)

export default RightPanel
