export const USER_FRIENDLY_ERRORS = {
  ERR001: 'Contact changes not saved. Please try again.',
  ERR002: 'Error with your contact record. Please try again.',
  ERR003: 'Unable to update. Please update email or phone, not both.',
  ERR004:
    'Invalid entry. Please enter a valid phone or email address and try again.',
  ERR005: 'Contact changes not saved. Please try again.',
  ERR006:
    'Uh-oh, The contact details you are trying to edit, already belongs to another Contact with both email and phone assigned.',
  ERR007:
    'Uh-oh, looks like the contact details you entered is already associated with another Contact. Please try a different phone number.',
  ERR008:
    'Uh-oh, looks like the contact details you entered is already associated with another Contact. Please try a different email.',
  ERR009:
    'Changing this phone or email will result in the removal of messages from your conversation.',
  ERR010: 'Contact changes not saved. Please try again.',
  ERR011: 'Contact changes not saved. Please try again.',
  ERR012: 'Contact changes not saved. Please try again.',
  ERR013: 'Contact changes not saved. Please try again.',
  ERR014: 'Contact changes not saved. Please try again.',
  ERR015: 'Contact changes not saved. Please try again.',
  ERR016:
    'You are trying to combine two contacts. Your CRM has important details that would be lost if this contact is merged. Please update your CRM contact record.',
  ERR017: 'Invalid entry. Please enter a valid email address and try again.',
  ERR018: 'Contact changes not saved. Please try again.',
}
