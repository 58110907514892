import useSharedMediaProps from '../hooks/use-shared-media-props'

import SharedPhotosPreview from '../photos-panels/SharedPhotosPreview'
import SharedFilesPreview from '../files-panels/SharedFilesPreview'

export const PhotosAndFilesPreviewsPanel = ({
  className,
  show,
  toggleShowAllPhotos,
  toggleShowAllFiles,
}) => {
  const { mediaProps } = useSharedMediaProps()
  const allFilesAndPhotos = mediaProps.attachmentData

  return (
    show && (
      <div className={`${className} flex flex-col gap-[36px]`}>
        <SharedPhotosPreview
          show={allFilesAndPhotos.length > 0}
          onSeeAllClick={toggleShowAllPhotos}
        />

        <SharedFilesPreview
          show={allFilesAndPhotos.length > 0}
          onSeeAllClick={toggleShowAllFiles}
        />

        {allFilesAndPhotos.length === 0 && (
          <p className="text-thryv-gray-medium-500">
            Your sent and received attachments will appear here.
          </p>
        )}
      </div>
    )
  )
}
