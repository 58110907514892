import { useState } from 'react'

export const useError = () => {
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [timeoutId, setTimeoutId] = useState(0)

  const startErrorTimeout = () => {
    setShowError(true)
    clearTimeout(timeoutId)

    const newTimeoutId = setTimeout(() => {
      setShowError(false)
    }, 10000)

    setTimeoutId(newTimeoutId)
  }

  const updateErrorMessage = (message) => {
    clearTimeout(timeoutId)
    startErrorTimeout()
    setShowError(true)
    setErrorMessage(message)
  }

  const hideErrorMessage = () => {
    clearTimeout(timeoutId)
    setShowError(false)
  }

  return {
    showError,
    errorMessage,
    hideErrorMessage,
    updateErrorMessage,
  }
}
