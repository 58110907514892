import { useState } from 'react'

// Components
import { Header } from '@thryvlabs/maverick'
import EditContactPanelUpdated from '../../calls/contacts/edit-contact-panel/EditContactPanelUpdated'
import { ContactPanel } from './contact-panel/ContactPanel'
import { CloseModalButton } from '../mobile/components/CloseModalButton'
import { IoIosArrowBack } from 'react-icons/io'
import { HorizontalLine } from '../mobile/components/HorizontalLine'
import { ContactsContextProvider } from './contexts/contacts-context'
import { FormContextProvider } from './contexts/form-context'

const BackButton = ({ className, onClick, hidden, ...rest }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`${className} ${hidden && 'opacity-0 pointer-events-none'}`}
      aria-label="Back to Contact Section"
      {...rest}
    >
      <IoIosArrowBack size={25} color="#4D4D4D" />
    </button>
  )
}

const UserProfileAndActivity = ({
  isMobile = false,
  onCloseModalButtonClick = () => {},
  openConnectPhoneModal = () => {},
}) => {
  const isDesktop = !isMobile

  // OPTIONS: contact, edit
  const [selectedPanel, setSelectedPanel] = useState('contact')

  return (
    <ContactsContextProvider>
      <div
        className={`flex justify-between items-center ${
          isMobile ? 'my-[16px]' : 'mb-[16px]'
        }`}
      >
        <BackButton
          id="contact-panel-back-button"
          hidden={selectedPanel !== 'edit'}
          onClick={() => setSelectedPanel('contact')}
        />

        <Header
          className={`${isMobile ? 'text-[18px]' : 'text-[16px]'}`}
          fontWeight="semibold"
          variant="h7"
        >
          Contact Card
        </Header>

        <CloseModalButton
          className={`${
            isDesktop && 'opacity-0 pointer-events-none'
          } w-[25px] h-[25px]`}
          onClick={onCloseModalButtonClick}
          aria-label="Close Contact Modal"
        />
      </div>

      {isMobile && <HorizontalLine className="mb-[24px]" />}

      <div className="grow flex flex-col">
        <FormContextProvider>
          <EditContactPanelUpdated
            show={selectedPanel === 'edit'}
            isMobile={isMobile}
          />
        </FormContextProvider>

        <ContactPanel
          className="grow"
          openConnectPhoneModal={openConnectPhoneModal}
          show={selectedPanel === 'contact'}
          isMobile={isMobile}
          onEditClick={() => {
            setSelectedPanel('edit')
          }}
        />
      </div>
    </ContactsContextProvider>
  )
}

UserProfileAndActivity.displayName = 'UserProfileAndActivity'

export default UserProfileAndActivity
