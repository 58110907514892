import { ChannelsContainer } from './ModalComponent.styles'
import ConnectCard from './ConnectCard'
import { Icon, Header, Button } from '@thryvlabs/maverick'
import { useEffect, useState } from 'react'
import { ErrorBanner, SuccessBanner } from '../../helper-components'
import { Notification } from '../../notification/notification'
import { useSelector } from 'react-redux'
import {
  handleHideDisconnectedChannelsNotification,
  handleHideDisconnectingChannelNotification,
  setChannelConnectedToAnotherCC,
  setConnectionError,
  setMeta400Error,
  setModalContent,
  setNoWebsiteToConnectError,
  setShowFirstChannelModal,
  setShowModal,
} from '../slices/connectChannelsSlice'
import { useDispatch } from 'react-redux'
import { setPaymentProcessed } from '../upgrade-plan-modal/slices/planInfoSlice'
import { CredentialChangedNotification } from '../../notification'
import RegisterPhoneNotifications from '../phone-certification-modal/register-phone-modal/RegisterPhoneNotifications'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@apollo/client'
import {
  GET_CC_PLANS_INFO,
  GET_RECURLY_ACCOUNT_INFO,
  GET_STAFF,
} from '../../../../graphql'
import { setCCRecurlyPlan } from '../../../inbox/slices/inboxSlice'
import { syncCCPlanPrices } from '../upgrade-plan-modal/helpers'
import RegisterBusinessErrorNotif from '../register-business-name-modal/RegisterBusinessErrorNotif'
import usePhoneRegistrationCalls from '../../../inbox/hooks/usePhoneRegistrationCalls'
import { AnimatedNotificationContainer } from '../../../inbox/message-content/inbox-notifications/animated-notification-container/animated-notification-container'
import { useInboxNotifications } from '../../../inbox/message-content/inbox-notifications/use-inbox-notifications'
import usePhoneChannel from '../../../../hooks/use-phone-channel'
import { useFlags } from 'launchdarkly-react-client-sdk'
import thryvccwhite from '@assets/thryv-cc-white.png'
import { cloneDeep } from '@apollo/client/utilities'
import { useMediaQuery } from 'react-responsive'

export const priority = {
  calls: 1,
  gmail: 2,
  webchat: 3,
  facebookMessenger: 4,
  instagram: 5,
  video: 6,
  outlook: 7,
  imap: 8,
  icloud: 9,
  yahoo: 10,
  hotmail: 11,
  microsoft365: 12,
  default: 100,
}

function ConnectChannelsModal({
  manageChannel,
  setChannelIsConnected,
  setClickedID,
  handleClose,
}) {
  const isMobile = useMediaQuery({ maxWidth: 835 })
  const {
    cardData,
    channelDisconnected,
    showFirstChannelModal,
    showWelcomeChannelModal,
  } = useSelector((state) => state.connectChannels)
  const sortedCardDataByPopularity = cloneDeep(cardData)

  sortedCardDataByPopularity.sort((a, b) => {
    return (
      (priority[a.iconName] || priority.default) -
      (priority[b.iconName] || priority.default)
    )
  })

  const [accountInfo, setAccountInfo] = useState({})
  const dispatch = useDispatch()
  const { user: authUser } = useAuth0()
  const userCurrency = authUser?.country + 'D'
  const { data: accountData } = useQuery(GET_RECURLY_ACCOUNT_INFO, {
    variables: {
      CCID: authUser?.cc_id,
    },
    onCompleted: () => {
      setAccountInfo(accountData.queryRecurlyAccountInfo)
    },
  })
  const { toggleStaffSeatUpdates } = useFlags()

  const { data: planData } = useQuery(GET_CC_PLANS_INFO, {
    variables: {
      CCID: authUser?.cc_id,
      currency: userCurrency,
    },
    onCompleted: () => {
      const { queryRecurlyCommandCenterPlans } = planData
      const newPlanData = syncCCPlanPrices(
        queryRecurlyCommandCenterPlans,
        accountInfo.activePlanCode,
        toggleStaffSeatUpdates,
      )
      dispatch(setCCRecurlyPlan(newPlanData))
    },
  })

  useEffect(() => {
    if (showFirstChannelModal) dispatch(setShowFirstChannelModal(false))
  }, [])

  usePhoneRegistrationCalls()

  const renderWelcomeConnectCards = () => {
    return sortedCardDataByPopularity.map((card, idx) => {
      const clickIndex = cardData.findIndex(
        (data) => data.iconName === card.iconName,
      )
      return (
        <ConnectCard
          id={card.id}
          key={card.iconName}
          iconName={card.iconName}
          iconType={card.iconType}
          ButtonText={card.ButtonText}
          clickedID={clickIndex}
          isConnected={card.isConnected}
          connectWarning={card.connectWarning}
          description={card.description}
          channelsConnected={card.channelsConnected}
          setClickedID={setClickedID}
          isWelcomeModal
          isPopular={
            idx === 0 || idx === 1 || idx === 2
            // || (isMobile && idx === 3)
          }
        />
      )
    })
  }

  if (showWelcomeChannelModal) {
    if (isMobile) {
      return (
        <>
          <div className="bg-white border border-[#ECEEF1] drop-shadow-md m-3 px-3">
            <p className="font-montserrat font-semibold text-thryv-black text-[28px] leading-8 py-4 px-2">
              Welcome! <br />
              Now get started by adding your first channel.
            </p>
            <div>
              <div className="grid sm:grid-cols-2 gap-y-8 gap-x-2 md:grid-cols-4 py-5">
                {renderWelcomeConnectCards()}
              </div>
              <div className="flex justify-center pb-5">
                <Button
                  variant="secondary"
                  className="w-[128px]"
                  onClick={handleClose}
                >
                  Skip
                </Button>
              </div>
            </div>
          </div>
        </>
      )
    }

    return (
      <>
        <div className="fixed inset-0 bg-white">
          <div className="absolute top-[-50%] left-[-10vw] w-[120%] h-full bg-gradient-to-r from-thryv-cornflower to-thryv-sapphire-500 rotate-[-7deg]" />
        </div>
        <div className="fixed rounded translate-x-[-50%] translate-y-[-50%] top-2/4 left-2/4">
          <div className="text-center">
            <div className="flex justify-center">
              <img src={thryvccwhite} />
            </div>
            <p className="py-7 text-white font-montserrat font-semibold text-[28px]">
              Welcome! Now get started by adding your first channel.
            </p>
            <div className="w-[1040px] max-h-[550px] bg-white rounded-[12px] border border-[#ECEEF1] drop-shadow-md px-5 py-10">
              <div className="grid grid-rows-2 grid-cols-6 gap-y-8 py-5">
                {renderWelcomeConnectCards()}
              </div>
              <div className="flex justify-end pr-5 pt-5">
                <Button
                  variant="secondary"
                  className="w-[128px]"
                  onClick={handleClose}
                >
                  Skip
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <div
      id="connect-channels-modal-container"
      className="p-5 sm:p-2 w-full mx-auto sm:h-[609px] sm:overflow-scroll sm:no-scrollbar"
    >
      <ConnectHeader
        setChannelIsConnected={setChannelIsConnected}
        handleClose={handleClose}
      />
      <ChannelsContainer className="gap-x-2 gap-y-4">
        {cardData.map((card, idx) => {
          return (
            <ConnectCard
              id={card.id}
              key={card.iconName}
              iconName={card.iconName}
              iconType={card.iconType}
              ButtonText={card.ButtonText}
              clickedID={idx}
              isConnected={card.isConnected}
              manageChannel={manageChannel}
              connectError={
                card.connectError ||
                (card.iconName === channelDisconnected[0]?.Provider ? true : false)
              }
              connectWarning={card.connectWarning}
              description={card.description}
              channelsConnected={card.channelsConnected}
              setClickedID={setClickedID}
            />
          )
        })}
      </ChannelsContainer>
      <div className="w-full flex justify-end">
        <Button
          className="px-4 pt-5"
          variant="text"
          onClick={() => handleClose()}
          level={2}
        >
          Done
        </Button>
      </div>
    </div>
  )
}

const ConnectHeader = ({ handleClose }) => {
  const {
    channelInfo,
    maxChannelsReached,
    channelIsConnected,
    channelDisconnected,
    showChannelDisconnectedNotification,
    channelDisconnecting,
    showChannelDisconnectingNotification,
    connectedChannelInfo,
    channelConnectedToAnotherCC,
    connectionError,
    meta400Error,
    cardData,
    maxChannelsAllowed,
    noWebsiteToConnectError,
  } = useSelector((state) => state.connectChannels)

  const dispatch = useDispatch()
  const { currentPlan, recentlyUpgraded } = useSelector((state) => state.planInfo)
  const { user } = useAuth0()
  const userId = user.cc_uid
  const { data: singleStaffData } = useQuery(GET_STAFF, {
    variables: {
      input: {
        CC_UserID: { eq: userId },
      },
    },
  })
  const userRole = singleStaffData?.staff[0]?.Role || user.cc_role
  const channelId = localStorage.getItem('channelClicked')
  const { inboxNotifications } = useInboxNotifications()
  const importedRegisterBusinessErrorNotif = inboxNotifications.find(
    (notification) => notification.name === 'importedRegisterBusinessErrorNotif',
  )
  const campaignStatus = useSelector(
    (state) => state.phoneRegistration.brand?.campaign_status,
  )
  const brandStatus = useSelector(
    (state) => state.phoneRegistration.brand?.brand_status,
  )
  const countryISO = useSelector((state) => state.countryCode.countryIso2)

  const [phoneChannel] = usePhoneChannel()
  const { toggleTenDlc } = useFlags()
  const isActive =
    toggleTenDlc ||
    countryISO === 'AU' ||
    countryISO === 'CA' ||
    countryISO === 'NZ' ||
    brandStatus === 'PENDING' ||
    brandStatus === 'ACTIVE' ||
    brandStatus === 'VERIFIED' ||
    brandStatus === 'UNVERIFIED' ||
    campaignStatus === 'UPDATES_REQUIRED' ||
    campaignStatus === 'PENDING_REVIEW' ||
    campaignStatus === 'ACTIVE' ||
    phoneChannel?.ChannelAccessLevel === '2'

  const UpgradeButton = () => (
    <Button
      className="rounded-[56px] border-2 focus:outline-none flex items-center justify-center"
      variant="primary"
      onClick={() => dispatch(setModalContent(4))}
    >
      UPGRADE
    </Button>
  )

  return (
    <div className="flex flex-col py-4 items-start justify-center ">
      <div className="flex justify-between items-center gap-8 w-full mb-3">
        <div className="flex gap-4 items-center">
          <Header
            fontWeight="semibold"
            variant="h4"
            className="sm:text-[18px] x-sm:text-[14px]"
          >
            {maxChannelsAllowed === 'Unlimited'
              ? 'My Channels'
              : `My Channels (${
                  channelInfo ? channelInfo?.length : 0
                }/${maxChannelsAllowed})`}
          </Header>
          {userRole !== '2' && <UpgradeButton />}
        </div>
        <Button variant="text" level={2}>
          <Icon
            type="regular"
            variant="x"
            height="18"
            fill="#A3A5A7"
            onClick={() => {
              handleClose()
              dispatch(setShowModal(false))
            }}
          />
        </Button>
      </div>

      <div
        id="notifications-container"
        className="flex flex-col gap-2 max-w-[37.6rem]"
      >
        {channelIsConnected && !channelConnectedToAnotherCC ? (
          <SuccessBanner />
        ) : null}

        {connectionError ? (
          <Notification
            variant="error"
            iconType="solid"
            iconVariant="circleExclamation"
            className="text-left gap-x-3"
            textToRender={`Looks like there was an error connecting your ${cardData[channelId].iconName} account. Please try again`}
            close={() => dispatch(setConnectionError(false))}
          />
        ) : null}

        {connectionError &&
        (cardData[channelId].iconName === 'outlook' ||
          cardData[channelId].iconName === 'microsoft365') ? (
          <Notification
            variant="warning"
            iconType="solid"
            iconVariant="circleExclamation"
            className="text-left gap-x-3"
            textToRender={
              <span>
                Having trouble connecting to Outlook or Microsoft 365? Refer to our{' '}
                <a
                  href="https://learn.thryv.com/hc/en-us/articles/27318943041805-Error-When-Connecting-Microsoft-365"
                  target="_blank"
                  className="text-[#057AFF]"
                  rel="noreferrer"
                >
                  support article
                </a>{' '}
                for potential workarounds.
              </span>
            }
            close={() => dispatch(setConnectionError(false))}
          />
        ) : null}

        {meta400Error && (
          <Notification
            variant="warning"
            iconType="solid"
            iconVariant="circleExclamation"
            className="text-left gap-x-3"
            textToRender={meta400Error}
            close={() => dispatch(setMeta400Error(''))}
          />
        )}

        {noWebsiteToConnectError ? (
          <Notification
            variant="error"
            iconType="solid"
            iconVariant="circleExclamation"
            className="text-left gap-x-3"
            textToRender={`Uh-uh! Looks like you don’t have a Thryv Website. Please select the option for External Website, or check out Website Builder in the Marketplace to get your own Thryv Website.`}
            close={() => dispatch(setNoWebsiteToConnectError(true))}
          />
        ) : null}

        {maxChannelsReached ? <ErrorBanner /> : null}

        {/* banner to show if channel is connected to other CC */}
        {channelConnectedToAnotherCC ? (
          <Notification
            variant="warning"
            iconType="solid"
            iconVariant="circleExclamation"
            className="text-left gap-x-3 sm:w-full sm:text-center"
            textToRender={`Looks like the email you are trying to connect (${connectedChannelInfo.email}) is already associated with another Command Center`}
            close={() => dispatch(setChannelConnectedToAnotherCC(false))}
          />
        ) : null}
        {channelDisconnected?.length > 0 && showChannelDisconnectedNotification ? (
          <CredentialChangedNotification
            variant="error"
            iconType="solid"
            iconVariant="circleExclamation"
            className="text-left gap-x-3 gap-y-6 sm:w-full"
            handleHideDisconnectedChannelsNotification={() =>
              dispatch(handleHideDisconnectedChannelsNotification(false))
            }
            updateButton={false}
          />
        ) : null}
        {channelDisconnecting !== '' && showChannelDisconnectingNotification ? (
          <CredentialChangedNotification
            variant="success"
            iconType="solid"
            iconVariant="circleCheck"
            className="text-left gap-x-3 gap-y-6 sm:w-full"
            handleHideDisconnectingChannelNotification={() =>
              dispatch(handleHideDisconnectingChannelNotification(false))
            }
            updateButton={false}
          />
        ) : null}

        {isActive && (
          <RegisterPhoneNotifications className="text-left gap-x-3 gap-y-6 sm:w-full" />
        )}
        {importedRegisterBusinessErrorNotif && (
          <AnimatedNotificationContainer
            show={importedRegisterBusinessErrorNotif.show}
          >
            <RegisterBusinessErrorNotif className="text-left gap-x-3 gap-y-6 sm:w-full" />
          </AnimatedNotificationContainer>
        )}

        {recentlyUpgraded ? (
          <Notification
            variant="success"
            iconType="solid"
            iconVariant="circleExclamation"
            className="text-left gap-x-3 gap-y-6 sm:w-full sm:text-center"
            textToRender={`Thanks, ${user.name}! You have successfully ${
              currentPlan?.header == 'Professional' ? 'upgraded' : 'changed'
            } your plan to ${currentPlan?.header} and now have ${maxChannelsAllowed
              ?.toString()
              .toLowerCase()} channels! You will receive a receipt via email shortly. It can take up to 60 seconds for your updated plan to be reflected in your active subscriptions`}
            close={() => dispatch(setPaymentProcessed(false))}
          />
        ) : null}
      </div>

      <p className="font-montserrat font-semibold text-base	text-center sm:mb-8 mt-2">
        Connect your favorite channels for your business communication
      </p>
    </div>
  )
}
export default ConnectChannelsModal
