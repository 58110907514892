import { TooltipWithCenteredArrow } from '../../../common/tooltip-with-centered-arrow'
import { useEffect, useState } from 'react'

import { FaVideo } from 'react-icons/fa'

const CircleVideoIcon = ({ circleColor = '#F3F5F9', iconColor = '#4D4D4D' }) => {
  return (
    <div
      className="rounded-full h-[38px] w-[38px] flex justify-center items-center"
      style={{ backgroundColor: circleColor }}
    >
      <FaVideo color={iconColor} size="17" />
    </div>
  )
}

export const VideoButton = ({
  className,
  onClick,
  disabled,
  isActive,
  tooltipVisible,
  ...rest
}) => {
  const [isHover, setIsHover] = useState(false)

  // The colors shown here are the defaults
  const [circleColor, setCircleColor] = useState('#F3F5F9')
  const [iconColor, setIconColor] = useState('#4D4D4D')

  const initDefaultColors = () => {
    setCircleColor('#F3F5F9')
    setIconColor('#4D4D4D')
  }

  const initHoverColors = () => {
    setCircleColor('#057AFF')
    setIconColor('#FFFFFF')
  }

  const initActiveColors = () => {
    setCircleColor('#16A085')
    setIconColor('#ffffff')
  }

  useEffect(() => {
    isActive ? initActiveColors() : initDefaultColors()
  }, [])

  useEffect(() => {
    if (isHover) {
      initHoverColors()
    } else if (isActive) {
      initActiveColors()
    } else {
      initDefaultColors()
    }
  }, [isHover])

  return (
    <TooltipWithCenteredArrow
      variant="bottom"
      title="Click the video icon to join the on-going call."
      disableVisibleOnClick={true}
      visible={tooltipVisible}
      width="175px"
    >
      <button
        onClick={onClick}
        disabled={disabled}
        className={`${className} ${disabled && 'opacity-30 pointer-events-none'}`}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        {...rest}
      >
        <CircleVideoIcon circleColor={circleColor} iconColor={iconColor} />

        <p className='font-["Open_Sans"] font-normal text-xs mt-1'>Video</p>
      </button>
    </TooltipWithCenteredArrow>
  )
}
