import { forwardRef, useState } from 'react'
import { useClickOutside } from '../../../hooks'
import { areExcludedItemsClicked } from '../utils/areExcludedItemsClicked'

// Components
import { ShowAttachmentsButton } from './ShowAttachmentsButton'
import { ShowProfileButton } from './ShowProfileButton'

const RightPanelButtons = forwardRef(
  (
    {
      className,
      onAttachmentsButtonClick,
      onProfileButtonClick,
      defaultActiveButton,
    },
    forwardedRef,
  ) => {
    // OPTIONS: profile, attachments, none
    const [activeButton, setActiveButton] = useState(defaultActiveButton || 'none')

    const onProfileButtonClicked = () => {
      if (activeButton === 'profile') setActiveButton('none')
      else setActiveButton('profile')
      onProfileButtonClick('profile')
    }

    const onAttachmentsButtonClicked = () => {
      if (activeButton === 'attachments') setActiveButton('none')
      else setActiveButton('attachments')
      onAttachmentsButtonClick('attachments')
    }

    useClickOutside((e) => {
      const excludedItemsClicked = areExcludedItemsClicked(e)
      if (!excludedItemsClicked) setActiveButton('none')
    }, forwardedRef)

    return (
      <div className={`${className}`}>
        <div className="inline-block">
          <ShowAttachmentsButton
            onClick={onAttachmentsButtonClicked}
            isActive={activeButton === 'attachments'}
            className="mb-[4px]"
          />

          <ShowProfileButton
            onClick={onProfileButtonClicked}
            isActive={activeButton === 'profile'}
          />
        </div>
      </div>
    )
  },
)

export default RightPanelButtons
