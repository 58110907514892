import { useIsRightRailMobile } from '../../hooks/useIsRightRailMobile'
import useSharedMediaProps from '../hooks/use-shared-media-props'

// Components
import { DateDownloadHeader } from './DateDownloadHeader'
import { InteractiveImage } from './interactive-image/InteractiveImage'

const ImagesContainer = ({
  className,
  date = '10.21.2022',
  onDownloadAllClick,
  children,
}) => {
  const isRightRailMobile = useIsRightRailMobile()

  return (
    <section className={`${className}`}>
      <DateDownloadHeader
        className={`${isRightRailMobile ? 'mb-[24px]' : 'mb-[8px]'}`}
        button={{
          onDownloadAllClick: onDownloadAllClick,
          ariaLabel: `Download all images from ${date}`,
        }}
      >
        {date}
      </DateDownloadHeader>

      <ul
        className={`flex gap-[10px] flex-wrap ${
          isRightRailMobile ? 'justify-start' : 'justify-between'
        }`}
      >
        {children}
      </ul>
    </section>
  )
}

export const PhotosList = ({ className, imagesByDate }) => {
  const { allSharedPhotosProps } = useSharedMediaProps()

  return (
    <div className={`${className} flex flex-col gap-[22px]`}>
      {imagesByDate.map((imageGroup, i) => {
        const images = imageGroup.attachments

        return (
          <ImagesContainer
            key={i}
            date={imageGroup.date}
            onDownloadAllClick={() =>
              allSharedPhotosProps.handleDownloadAllPhotos(i)
            }
          >
            {images.map((image, i) => {
              const imageSrc = allSharedPhotosProps.imagePreviewPhotos.get(
                image.body,
              )

              const imageIsLoading = Boolean(imageSrc) === false

              return (
                <li key={i}>
                  <InteractiveImage
                    variant="interactive"
                    image={{
                      filesk: image.filesk,
                      src: imageSrc,
                      alt: `Image ${i + 1}`,
                      expand: () => allSharedPhotosProps.handleClickImage(image, i),
                      download: () =>
                        allSharedPhotosProps.handlePhotoAttachmentDownload(image, i),
                    }}
                    isLoading={imageIsLoading}
                  />
                </li>
              )
            })}
          </ImagesContainer>
        )
      })}
    </div>
  )
}
