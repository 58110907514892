/**
 * 
 * EXAMPLE OUTPUT
 * 
 * [
      {
        "date": "09.04.2024",
        "attachments": [
          {
            "__typename": "Attachment",
            "pk1": "CC_BA266E9D#16cc2bbf-6ae1-11ef-95ca-0220bfccfc55#YjEyOTQxZmMtMzEwMC00NWQ5LWJlZjUtMGY0NzMxZDcxMzgy#FILE",
            "body": "{\"id\":\"AAkALgAAAAAAHYQDEapmEc2byACqAC-EWg0A2IWoJos1XUSap-GthglTMQAA9kp-LAAAARIAEAALqggpTCo8QYhzD9l3WXNF\",\"filename\":\"Emailing myself\",\"size\":2617,\"contentType\":\"message/rfc822\"}",
            "sk1": 1725470153172,
            "filesk": "1725470142000"
          }
        ]
      }
    ]
 * 
 * 
 */

export function transformAndSortAttachments(attachments, variant) {
  if (variant === 'desc') {
    return Object.keys(attachments)
      .sort((a, b) => new Date(b) - new Date(a)) // Sort dates in descending order
      .map((date) => ({
        date,
        attachments: attachments[date],
      }))
  }
}
