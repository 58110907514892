import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FaPhoneAlt } from 'react-icons/fa'

const CirclePhoneIcon = ({ circleColor = '#F3F5F9', iconColor = '#4D4D4D' }) => {
  return (
    <div
      className="rounded-full h-[38px] w-[38px] flex justify-center items-center"
      style={{ backgroundColor: circleColor }}
    >
      <FaPhoneAlt color={iconColor} size={17} />
    </div>
  )
}

export const PhoneButton = ({ className, onClick, disabled, ...rest }) => {
  const [isHover, setIsHover] = useState(false)
  const [circleColor, setCircleColor] = useState('#F3F5F9')
  const [iconColor, setIconColor] = useState('#4D4D4D')

  useEffect(() => {
    if (isHover) {
      setCircleColor('#057AFF')
      setIconColor('#FFFFFF')
    } else {
      setCircleColor('#F3F5F9')
      setIconColor('#4D4D4D')
    }
  }, [isHover])

  return (
    <Link
      onClick={onClick}
      to="/calls"
      {...rest}
      className={`${className} ${disabled && 'opacity-30 pointer-events-none'}`}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <button disabled={disabled}>
        <CirclePhoneIcon circleColor={circleColor} iconColor={iconColor} />
        <p className='font-["Open_Sans"] font-normal text-xs mt-1'>Call</p>
      </button>
    </Link>
  )
}
