import { Icon } from '@thryvlabs/maverick'

const PasswordVerification = ({
  isLengthValid,
  hasUpperCase,
  hasLowerCase,
  hasNumber,
  hasSpecialChar,
  containsPersonalInfo,
}) => {
  return (
    <>
      <div className="mt-[5px] flex col-span-2 items-center">
        <Icon
          type={isLengthValid ? 'solid' : 'regular'}
          variant="circleCheck"
          height="12px"
          width="12px"
          fill={isLengthValid ? '#16A085' : '#CCCCCC'}
          className="pr-1"
        />
        <p className='font-["Open_Sans"] font-normal text-[12px] leading-[18px] text-[#808080]'>
          Min. 8 characters
        </p>
      </div>
      <div className="flex col-span-2 items-center">
        <Icon
          type={hasUpperCase ? 'solid' : 'regular'}
          variant="circleCheck"
          height="12px"
          width="12px"
          fill={hasUpperCase ? '#16A085' : '#CCCCCC'}
          className="pr-1"
        />
        <p className='font-["Open_Sans"] font-normal text-[12px] leading-[18px] text-[#808080]'>
          Min. 1 upper-case letter
        </p>
      </div>
      <div className="flex col-span-2 items-center">
        <Icon
          type={hasLowerCase ? 'solid' : 'regular'}
          variant="circleCheck"
          height="12px"
          width="12px"
          fill={hasLowerCase ? '#16A085' : '#CCCCCC'}
          className="pr-1"
        />
        <p className='font-["Open_Sans"] font-normal text-[12px] leading-[18px] text-[#808080]'>
          Min. 1 lower-case letter
        </p>
      </div>
      <div className="flex col-span-2 items-center">
        <Icon
          type={hasNumber ? 'solid' : 'regular'}
          variant="circleCheck"
          height="12px"
          width="12px"
          fill={hasNumber ? '#16A085' : '#CCCCCC'}
          className="pr-1"
        />
        <p className='font-["Open_Sans"] font-normal text-[12px] leading-[18px] text-[#808080]'>
          Min. 1 number
        </p>
      </div>
      <div className="flex col-span-2 items-center">
        <Icon
          type={hasSpecialChar ? 'solid' : 'regular'}
          variant="circleCheck"
          height="12px"
          width="12px"
          fill={hasSpecialChar ? '#16A085' : '#CCCCCC'}
          className="pr-1"
        />
        <p className='font-["Open_Sans"] font-normal text-[12px] leading-[18px] text-[#808080]'>
          A special character
        </p>
      </div>
      <div className="mb-[45px] md:mb-[25px] flex col-span-2 items-center">
        <Icon
          type={containsPersonalInfo ? 'solid' : 'regular'}
          variant="circleCheck"
          height="12px"
          width="12px"
          fill={containsPersonalInfo ? '#16A085' : '#CCCCCC'}
          className="pr-1"
        />
        <p className='font-["Open_Sans"] font-normal text-[12px] leading-[18px] text-[#808080]'>
          Cannot contain personal info
        </p>
      </div>
    </>
  )
}

export default PasswordVerification
