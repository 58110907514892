import { useState, useEffect, useRef } from 'react'
import { Route, Routes, useSearchParams } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { GET_CONNECTED_CHANNELS_BY_USER } from '../graphql'
import { useLazyQuery } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch, useSelector, batch } from 'react-redux'
import {
  setChanelInfo,
  setShowFirstChannelModal,
} from '../components/common/modals/slices/connectChannelsSlice'
import { InboxMobileNavBar } from '../components/inbox/inbox-mobile-nav-bar'
import { DynamicPageTitle as Title } from '@title'
import GlobalSearchSection from '../components/inbox/global-search-section'
import RightPanel from '../components/right-panel/RightPanel'
import { InboxMenu, MessageContent } from '../components/inbox'
import ChannelActivationBar from '../components/channel-activation-bar/ChannelActivationBar'
import usePhoneRegistrationCalls from '../components/inbox/hooks/usePhoneRegistrationCalls'
import { connectChannelsSelector } from '../components/common/modals/slices/connectedChannels.selectors'
import { selectedInboxMobilePageSelector } from '../components/inbox/slices/inbox-slice.selectors'
import ThryvMetaAuthorizationModal from '../components/common/modals/thryv-meta-authorization-modals/ThryvMetaAuthorizationModal'
import ErrorModal from '../components/common/modals/thryv-meta-authorization-modals/ErrorModal'
import SelectPageModal from '../components/common/modals/thryv-meta-authorization-modals/SelectPageModal'
import {
  setShowModal,
  setDisableBackdropClick,
  setModalContent,
} from '../components/common/modals/slices/connectChannelsSlice'

import {
  setMessageInModal,
  setViewFullMessageOpen,
} from '../components/inbox/slices/inboxSlice'
import CustomModal from '../components/common/CustomModal/Modal'
import MessageModal from '../components/inbox/message-content/message-components/message-modal/message-modal'
import useReauthModals from '../components/common/modals/nylasv3-migration-modal/useReauthModals'
import useStaffSeatChangeModals from '../components/common/modals/staff-seat-change-modals/useStaffSeatChangeModals'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useConnectPhoneModal } from '../components/common/modals/phone-certification-modal/connect-phone-modal/ConnectPhoneModal'
const InboxPageWrapper = () => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const { user, getAccessTokenSilently } = useAuth0()
  const { selectedInboxMobilePage } = useSelector(selectedInboxMobilePageSelector)
  const { channelIsConnected } = useSelector(connectChannelsSelector)
  const [showPanel, setShowPanel] = useState(true)
  const rightPanelRef = useRef(null)
  const rightPanelButtonsRef = useRef(null)
  const isMobile = useMediaQuery({ maxWidth: 835 })
  const isSmallMobile = useMediaQuery({ maxWidth: 375 })
  const { modalContent } = useSelector((state) => state.connectChannels)
  const [success, setSuccess] = useState(false)
  const { toggleStaffSeatUpdates } = useFlags()
  const [openConnectPhoneModal, , ConnectPhoneModal] = useConnectPhoneModal()

  useEffect(() => {
    if (channelIsConnected) {
      getAccessTokenSilently()
    }
  }, [channelIsConnected, getAccessTokenSilently])

  const [getChannelsByUser] = useLazyQuery(GET_CONNECTED_CHANNELS_BY_USER, {
    variables: {
      loc_id: user.businessId,
      user_id: user.cc_uid,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const activeChannels = data?.queryConnectedChannelsByUser?.filter(
        (channel) => channel.Provider !== 'CC' && channel.Status !== '0',
      )
      if (activeChannels) {
        dispatch(setChanelInfo(activeChannels))
        setSuccess(false)
      }
      if (success) {
        dispatch(setShowFirstChannelModal(true))
      }
    },
  })

  usePhoneRegistrationCalls()

  const { upcomingImprovementsModal } = useReauthModals()

  const { staffSeatChangeModal } = useStaffSeatChangeModals()

  useEffect(() => {
    getChannelsByUser()
  }, [success, channelIsConnected])

  const { viewFullMessageOpen, messageInModal } = useSelector((state) => state.inbox)

  const handleClose = () => {
    dispatch(setShowModal(false))
    dispatch(setDisableBackdropClick(false))
    dispatch(setModalContent(1))
  }

  const HandleMetaModals = () => {
    if (modalContent === 10) {
      return (
        <CustomModal
          isOpen={true}
          width={isSmallMobile ? '300px' : isMobile ? '330px' : '500px'}
        >
          <ThryvMetaAuthorizationModal handleClose={handleClose} />
        </CustomModal>
      )
    }

    if (modalContent === 11) {
      return (
        <CustomModal
          isOpen={true}
          width={isSmallMobile ? '300px' : isMobile ? '330px' : '500px'}
        >
          <SelectPageModal handleClose={handleClose} setSuccess={setSuccess} />
        </CustomModal>
      )
    }
    if (modalContent === 12) {
      return (
        <CustomModal
          isOpen={true}
          width={isSmallMobile ? '300px' : isMobile ? '330px' : '500px'}
        >
          <ErrorModal handleClose={handleClose} />
        </CustomModal>
      )
    }
  }

  useEffect(() => {
    HandleMetaModals()
  }, [modalContent])

  const openUpgradePlanModal = () => {
    dispatch(setModalContent(4))
    dispatch(setDisableBackdropClick(true))
    dispatch(setShowModal(true))
  }

  useEffect(() => {
    if (searchParams?.get('upgrade_account') === 'true') {
      setTimeout(() => {
        openUpgradePlanModal()
      }, 1000)
    }
  }, [])

  return (
    <div id="cc-inbox-content">
      <Title pageTitle="Command Center | Inbox" />

      {isMobile && (
        <InboxMobileNavBar
          className="[grid-area:inbox-nav] min-w-md:hidden"
          disableMenu={selectedInboxMobilePage !== 'inbox-menu'}
        />
      )}

      {!isMobile && (
        <div
          id="cc-channel-activation-bar"
          className="flex justify-center items-center font-bold md:hidden"
        >
          <ChannelActivationBar />
        </div>
      )}

      {toggleStaffSeatUpdates && staffSeatChangeModal}

      {/* rendering view full message modal here so it doesnt close on rerender when a new msg comes in*/}
      <CustomModal
        isModalForm={true}
        isOpen={viewFullMessageOpen}
        close={() => {
          batch(() => {
            dispatch(setViewFullMessageOpen(false))
            dispatch(setMessageInModal(''))
          })
        }}
        width={isMobile ? '325px' : '1000px'}
      >
        <MessageModal body={messageInModal} />
      </CustomModal>

      {upcomingImprovementsModal}

      <div id="inbox-sections">
        {HandleMetaModals()}

        {selectedInboxMobilePage === 'inbox-menu' || !isMobile ? (
          <div>
            <InboxMenu />
          </div>
        ) : null}
        {selectedInboxMobilePage === 'message-content' || !isMobile ? (
          <div className="flex flex-row flex-row-reverse">
            <RightPanel
              ref={rightPanelRef}
              setShowPanel={setShowPanel}
              showPanel={showPanel}
              openConnectPhoneModal={openConnectPhoneModal}
            />

            <Routes>
              <Route
                path=""
                element={
                  <MessageContent className="grow" ref={rightPanelButtonsRef} />
                }
              />

              <Route path="global-search/*" element={<GlobalSearchSection />} />
            </Routes>
          </div>
        ) : null}
      </div>
      {ConnectPhoneModal}
    </div>
  )
}
export default InboxPageWrapper
