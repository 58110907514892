import { useEffect, useRef, useState } from 'react'
import useHandleImageClick from '../hooks/use-handle-image-click'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useApolloClient, useQuery } from '@apollo/client'
import {
  FETCH_ATTATCHMENTS_BY_THREAD_ID,
  GET_S3_DOWNLOAD_LINK,
} from '../../../../graphql'
import { getEpochToFormattedDate } from '../SharedMedia'
import { useSharedPhotos } from './use-shared-photos'

const useSharedMediaProps = () => {
  const client = useApolloClient()
  const isMobile = useMediaQuery({ maxWidth: 835 })

  // ---------- LOGIC FOR PHOTOS ---------- //
  const selectedThreadId = useSelector(
    (state) => state.inboxThreads.selectedThread.id,
  )
  const { imagePreviewPhotos, imageAttachmentData, groupedAttachments } =
    useSharedPhotos(selectedThreadId)
  const allImages = Array.from(imagePreviewPhotos, ([, values]) => values)
  const allAttachments = imageAttachmentData

  const {
    useImageLoader,
    imageLoaded,
    setImageLoaded,
    imageProcessing,
    setImageProcessing,
  } = useLoadImageState({ images: allImages })

  const {
    handleClickImage,
    handlePhotoAttachmentDownload,
    handleAttachmentDownloadBulk,
  } = useHandleImageClick({
    imageProcessing,
    setImageProcessing,
  })

  const handleDownloadAllPhotos = async (index) => {
    const attachments = Array.from(Object.values(groupedAttachments)[index])
    const fileDate = Object.keys(groupedAttachments)[index]
    await handleAttachmentDownloadBulk(attachments, `${fileDate}_photos`)
  }
  // ---------- END LOGIC FOR PHOTOS ---------- //

  // ---------- LOGIC FOR FILES ---------- //
  // Fetch attachments by thread ID using Apollo Client
  const { loading, data } = useQuery(FETCH_ATTATCHMENTS_BY_THREAD_ID, {
    variables: { threadid: selectedThreadId },
  })

  // Get the attachments or an empty array if data is null
  const attachmentData = data ? data.queryInboxAttachmentsByThread.items : []

  const sortedAttachmentData = [...attachmentData].sort((a, b) => b.sk1 - a.sk1)

  // Group the attachments by date
  const groupedFileAttachments = sortedAttachmentData
    .filter((attachment) => {
      const parsedData = JSON.parse(attachment.body)
      return !parsedData?.contentType?.startsWith('image/')
    })
    .reduce((groups, attachment) => {
      const date = getEpochToFormattedDate(attachment.sk1)
      if (!groups[date]) {
        groups[date] = []
      }

      groups[date].push(attachment)
      return groups
    }, {})

  const attachmentFiles = Object.values(groupedFileAttachments)
    .flat()
    .filter((attachment) => {
      const parsedData = JSON.parse(attachment.body)
      return !parsedData?.contentType?.startsWith('image/')
    })

  // Function to get S3 download link using Apollo Client
  const handleAttachmentLinkDownload = async (attachment) => {
    const { pk1, sk1 } = attachment
    const pk1Parts = pk1.split('#').slice(0, -1)
    const pk1String = pk1Parts.join('/').replace(/#/g, '/')
    const finalString = `${pk1String}/${sk1}`

    try {
      // Fetching the S3 download link
      const { data } = await client.query({
        query: GET_S3_DOWNLOAD_LINK,
        variables: {
          filename: finalString,
        },
      })

      const downloadLink = data.getSafeS3DownloadURL
      const formattedUrl = downloadLink.substring(
        downloadLink.indexOf('=') + 1,
        downloadLink.length - 1,
      )

      return formattedUrl
    } catch (error) {
      //
    }
  }

  // Function to handle downloading an attachment
  const handleFileAttachmentDownload = async (attachment) => {
    const formattedLink = await handleAttachmentLinkDownload(attachment)
    if (formattedLink) {
      // Downloading the file from the formatted link
      fetch(formattedLink)
        .then((resp) => {
          if (!resp.ok) {
            throw new Error(`HTTP error! status: ${resp.status}`)
          }
          return resp.blob()
        })
        .then((blob) => {
          // Blob download and save logic
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = url
          a.download = JSON.parse(attachment.body).filename
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        })
        .catch(() => {
          alert('An error occurred while downloading the file')
        })
    }
  }

  const handleDownloadAllAttachments = async (index) => {
    const attachments = Array.from(
      Object.values(groupedFileAttachments)[index].filter((attachment) => {
        const parsedData = JSON.parse(attachment.body)
        if (!isMobile) {
          return !parsedData?.contentType?.startsWith('image/')
        }
        return true
      }),
    )
    const fileDate = Object.keys(groupedAttachments)[index]

    await handleAttachmentDownloadBulk(attachments, `${fileDate}_files`)
  }
  // ---------- END LOGIC FOR FILES ---------- //

  const allSharedPhotosProps = {
    allAttachments,
    groupedAttachments,
    handleDownloadAllPhotos,
    imagePreviewPhotos,
    imageProcessing,
    handleClickImage,
    handlePhotoAttachmentDownload,
    imageLoaded,
    setImageLoaded,
    setImageProcessing,
    allImages,
    useImageLoader,
  }

  const allSharedFilesProps = {
    attachmentFiles,
    groupedFileAttachments,
    handleDownloadAllAttachments,
    handleFileAttachmentDownload,
    isMobile,
  }

  const mediaProps = {
    loading,
    attachmentData,
    sortedAttachmentData,
  }

  return {
    allSharedPhotosProps,
    allSharedFilesProps,
    mediaProps,
  }
}

export const useLoadImageState = ({ images }) => {
  const [imageLoaded, setImageLoaded] = useState(
    new Array(images.length).fill(false),
  )

  const [imageProcessing, setImageProcessing] = useState(
    new Array(images.length).fill(false),
  )
  const imageRefs = useRef([])

  // Preload images and update their loaded state

  const useImageLoader = () => {
    useEffect(() => {
      images.forEach((src, index) => {
        if (!imageLoaded[index]) {
          const img = new Image()
          img.src = src
          img.onload = () => {
            setImageLoaded((prevImageLoaded) => {
              // Create a copy and update the state
              const updatedImageLoaded = [...prevImageLoaded]
              updatedImageLoaded[index] = true
              return updatedImageLoaded
            })
          }
          imageRefs.current[index] = img
        }
      })

      // Cleanup: Release image references
      return () => {
        imageRefs.current = []
      }
    }, [images, imageLoaded])
  }

  return {
    useImageLoader,
    imageLoaded,
    setImageLoaded,
    imageProcessing,
    setImageProcessing,
  }
}

export default useSharedMediaProps
