import useSharedMediaProps from '../hooks/use-shared-media-props'
import { getFormattedFileSize } from '../utils/get-formatted-file-size'

// Components
import AttachmentCard from './AttachmentCard'
import { DateDownloadHeader } from './DateDownloadHeader'

const AttachmentsContainer = ({
  className,
  date = '10.21.2022',
  onDownloadAllClick,
  children,
}) => {
  return (
    <section className={`${className}`}>
      <DateDownloadHeader
        className="mb-[8px]"
        button={{
          onDownloadAllClick: onDownloadAllClick,
          ariaLabel: `Download all attachments ${date}`,
        }}
      >
        {date}
      </DateDownloadHeader>

      <ul className="flex gap-[10px] flex-col">{children}</ul>
    </section>
  )
}

export const AttachmentsList = ({ className, attachmentsByDates }) => {
  const { allSharedFilesProps } = useSharedMediaProps()

  const downloadAttachment = allSharedFilesProps.handleFileAttachmentDownload
  const downloadAllAttachments = allSharedFilesProps.handleDownloadAllAttachments

  return (
    <div className={`${className} flex flex-col gap-[24px]`}>
      {attachmentsByDates.map((data, i) => {
        const date = data.date
        const dateSpecificAttachments = data.attachments

        return (
          <AttachmentsContainer
            key={i}
            date={date}
            onDownloadAllClick={() => downloadAllAttachments(i)}
          >
            {dateSpecificAttachments.map((attachment, j) => {
              const parsedBody = JSON.parse(attachment.body)

              return (
                <li key={j}>
                  <AttachmentCard
                    file={{
                      sk: attachment.filesk,
                      name: parsedBody.filename,
                      size: getFormattedFileSize(parsedBody.size),
                      type: parsedBody.filename.slice(
                        parsedBody.filename?.lastIndexOf('.') + 1,
                      ),
                      download: () => downloadAttachment(attachment),
                    }}
                  />
                </li>
              )
            })}
          </AttachmentsContainer>
        )
      })}
    </div>
  )
}
