import { Toggle } from '@thryvlabs/maverick'
import { StatusPill } from '../../../../../../../../../../components/common/status-pill/status-pill'
import { LoadingSpinner } from '../../../../../../../../../../components/common/loading-spinner/loading-spinner'

export const PillWithToggle = ({
  className,
  isActive,
  removeToggle,
  onToggleChange,
  loading,
  role,
  totalData,
}) => {
  const handleToggleChange = () => {
    onToggleChange && onToggleChange()
  }

  return (
    <div
      className={`
        ${className} flex items-center gap-3
        ${loading && 'pointer-events-none'}`}
    >
      {!(totalData === 1 && role === 'owner') && (
        <Toggle
          data-testid="staff-toggle"
          className={`settings-staff-toggle
        ${removeToggle && 'pointer-events-none opacity-0'}`}
          toggleSize="small"
          onChange={handleToggleChange}
          toggled={isActive === !loading}
          customSelectedColor="#16A085"
          customUnselectedColor="#808080"
        />
      )}
      <StatusPill
        color={isActive === !loading ? '#16A085' : '#A3A5A7'}
        className="!w-[72px] !flex justify-center"
      >
        {loading ? (
          <LoadingSpinner widthAndHeight={'16px'} />
        ) : (
          <>{isActive ? 'ACTIVE' : 'INACTIVE'}</>
        )}
      </StatusPill>
    </div>
  )
}
