export const trimPhoneCountryCode = (phone, countryCode) => {
  if (phone === 'error') return ''

  switch (countryCode) {
    case 'US':
      return phone.substring(1)
    case 'CA':
      return phone.substring(1)
    case 'AU':
      return phone.substring(2)

    case 'NZ':
      return phone.substring(2)
  }
}
