import { useState, useEffect, forwardRef, useRef } from 'react'
import { MdOutlineArrowForwardIos } from 'react-icons/md'
import { Controller, useController } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { MaskedInput } from '@thryvlabs/maverick'

const ErrorText = ({ className, children }) => (
  <p
    className={`${className} text-[#DF2A2A] text-[12px] font-normal font-open-sans pt-[5px] h-[23px]`}
  >
    {children}
  </p>
)

const InputContainer = ({ title, htmlFor, children, error, disabled, ...rest }) => {
  const disabledStyles = 'opacity-40 pointer-events-none'

  return (
    <div
      className={`mx-[12px] py-[5px] max-w-full ${disabled && disabledStyles}`}
      {...rest}
    >
      <div
        className={`px-[4px] py-[6px] border-b-[1px] border-solid transition-all duration-300 ${
          error ? 'border-b-[#DF2A2A]' : 'border-b-[#ECEEF1]'
        } flex`}
      >
        <div className="w-[106px] mr-[8px] flex items-center">
          <label
            htmlFor={htmlFor}
            className="flex gap-[20px] items-center w-[106px] justify-between items-center"
          >
            <p className="text-[14px] font-open-sans font-normal text-[#808080] text-left">
              {title}
            </p>
            <MdOutlineArrowForwardIos color="#CCCCCC" size={15} />
          </label>
        </div>

        <div className="w-full">{children}</div>
      </div>

      <ErrorText className="pl-[4px] text-start">{error}</ErrorText>
    </div>
  )
}

// inputRef fixes the following error: Function components cannot be given refs.
const Input = forwardRef(({ error, ...rest }, inputRef) => (
  <input
    className={`w-full h-[30px] text-[14px] font-normal font-open-sans outline-none transition-all duration-300 ${
      error ? 'text-[#DF2A2A]' : 'text-[#231F20]'
    }`}
    ref={inputRef}
    {...rest}
  />
))

export const FirstNameInput = ({ control, error, ...rest }) => {
  return (
    <InputContainer title="First Name" htmlFor="firstName" error={error}>
      <Controller
        defaultValue="" // This fixes the - A component is changing an uncontrolled input to be controlled. - Error
        control={control}
        name="firstName"
        render={({ field }) => {
          return (
            <Input error={error} id="firstName" type="text" {...field} {...rest} />
          )
        }}
      />
    </InputContainer>
  )
}

export const LastNameInput = ({ control, error, ...rest }) => {
  return (
    <InputContainer title="Last Name" htmlFor="lastName" error={error}>
      <Controller
        defaultValue="" // This fixes the - A component is changing an uncontrolled input to be controlled. - Error
        control={control}
        name="lastName"
        render={({ field }) => {
          return (
            <Input error={error} id="lastName" type="text" {...field} {...rest} />
          )
        }}
      />
    </InputContainer>
  )
}

const WrappedMaskedInput = forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <MaskedInput {...props} />
    </div>
  )
})

// The Phone number included here has the country code ommitted
export const PhoneInput = ({
  control,
  phoneNumber = '(###) ###-####',
  error,
  disabled,
  ...rest
}) => {
  const phoneInputRef = useRef(null)
  const { countryIso2: countryCode } = useSelector((state) => state.countryCode)
  const { field } = useController({
    name: 'phone',
    control,
    defaultValue: phoneNumber, // Needed to
  })
  const [maskPattern, setMaskPattern] = useState('')

  const [value, setValue] = useState(phoneNumber)

  const handleChange = (e) => {
    setValue(e.target.value)
    field.onChange(e.target.value)
  }

  const setMask = (countryCode) => {
    switch (countryCode) {
      case 'US':
        return setMaskPattern('(###) ###-####')
      case 'CA':
        return setMaskPattern('(###) ###-####')
      case 'AU':
        if (
          phoneNumber[1] === '4' ||
          phoneNumber[1] === '5'
          // checks if mobile numbber and formats accordingly
        ) {
          return setMaskPattern('#### ### ###')
        } else {
          return setMaskPattern('(##) #### ####')
        }

      case 'NZ':
        return setMaskPattern('(##) ###-####')
    }
    return '(###) ###-####'
  }

  useEffect(() => {
    setMask(countryCode)
  }, [countryCode])

  useEffect(() => {
    setValue(phoneNumber)
  }, [phoneNumber])

  return (
    <InputContainer title="Mobile" htmlFor="phone" error={error} disabled={disabled}>
      <WrappedMaskedInput
        className={`w-full h-[27px] text-[14px] font-normal font-open-sans !outline-none transition-all duration-300 !border-b-[0px] mb-[2px] ${
          error ? 'text-[#DF2A2A]' : 'text-[#231F20]'
        }`}
        errors={error}
        customPlaceholder=" "
        id="phone"
        type="tel"
        name="phone"
        variant="default"
        value={value}
        onChange={handleChange}
        mask={maskPattern}
        {...field}
        {...rest}
        ref={phoneInputRef}
      />
    </InputContainer>
  )
}

export const EmailInput = ({ control, error, ...rest }) => {
  return (
    <InputContainer title="Email" htmlFor="email" error={error}>
      <Controller
        defaultValue="" // This fixes the - A component is changing an uncontrolled input to be controlled. - Error
        control={control}
        name="email"
        render={({ field }) => {
          return <Input error={error} id="email" type="email" {...field} {...rest} />
        }}
      />
    </InputContainer>
  )
}
