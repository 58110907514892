export const formatSelectedThreadName = (res) => {
  const body = res?.data?.update_contact?.body || ''
  const email = res?.data?.update_contact?.contactsk2 || ''
  const phone = res?.data?.update_contact?.contactsk3 || ''

  return body.length
    ? JSON.parse(body).given_name
      ? JSON.parse(body).given_name + ' ' + JSON.parse(body).surname
      : email
        ? email
        : phone
          ? phone
          : 'unknown'
    : 'unknown'
}
