import { forwardRef, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useMutation, useLazyQuery } from '@apollo/client'
import {
  FETCH_THREADS,
  REMOVE_THREAD_FIELD,
  UPDATE_THREAD_FIELD,
} from '../../../../../../../../graphql'
import { useInboxMenuConversations } from '../../../../../../inbox-menu/hooks/use-inbox-menu-conversation/use-inbox-menu-conversations'
import {
  setSelectedThreadTrash,
  setSelectedThread,
  setShowInboxNotification,
} from '../../../../../../slices/inboxThreadsSlice'
import { useIsRightRailMobile } from '../../../../../../../right-panel/hooks/useIsRightRailMobile'

// Components
import { TransitionContainer } from '../../../../../../../common/transition-container'
import { MenuButton } from '../menu-button'
import MobileSharedMediaModal from '../../../../../../../right-panel/mobile/mobile-shared-media-modal/MobileSharedMediaModal'
import { MobileContactCardModal } from '../../../../../../../right-panel/mobile/mobile-contact-card-modal/MobileContactCardModal'

export const TriDotMenu = forwardRef(
  ({ show, className, onButtonClick }, menuRef) => {
    const {
      selectedThreadTrash,
      selectedThreadSpam,
      inboxThreadSk1s,
      inboxPinnedThreadSk1s,
    } = useSelector((state) => state.inboxThreads)

    const selectedThreadId = useSelector(
      (state) => state.inboxThreads.selectedThread.id,
    )

    const dispatch = useDispatch()

    const [addThreadToSpam] = useMutation(UPDATE_THREAD_FIELD)
    const [removeThreadFromSpam] = useMutation(REMOVE_THREAD_FIELD)

    const [addThreadToTrash] = useMutation(UPDATE_THREAD_FIELD)
    const [removeThreadFromTrash] = useMutation(REMOVE_THREAD_FIELD)

    const [isAttachmentsModalOpen, setIsAttachmentsModalOpen] = useState(false)
    const [isContactsModalOpen, setIsContactsModalOpen] = useState(false)

    const threads = [...inboxThreadSk1s, ...inboxPinnedThreadSk1s]

    const sk1 = [threads.filter((x) => x.id === selectedThreadId)[0]?.sk1]
    const { createVariables, initMessageThreads, initPinnedMessageThreads } =
      useInboxMenuConversations()

    const [getThreads, { data: threadsData }] = useLazyQuery(FETCH_THREADS, {
      errorPolicy: 'ignore',
      fetchPolicy: 'network-only',
      variables: createVariables(),
      onCompleted: () => {
        const threads = threadsData?.queryThreads.items
        initMessageThreads(threads)
        const pinned = threadsData?.pinned?.items
        initPinnedMessageThreads(pinned)
      },
    })

    // ***** IMPORTANT *****
    // ADD TO "buttons" variable IF THERE IS ANOTHER BUTTON CLICK EVENT NEEDED.
    // Also make sure to invoke "onButtonClick()" at the end of your click event.
    const buttons = {
      spam: {
        click: async () => {
          const THREAD_IS_SPAM = selectedThreadSpam

          const MUTATION_OBJECT = {
            variables: { sk1s: sk1, field: 'spamsk' },
            onCompleted: () => {
              getThreads()
            },
          }

          if (THREAD_IS_SPAM) removeThreadFromSpam(MUTATION_OBJECT)
          else addThreadToSpam(MUTATION_OBJECT)

          onButtonClick()
        },
      },

      trash: {
        click: async () => {
          const THREAD_IS_TRASH = selectedThreadTrash

          const MUTATION_OBJECT = {
            variables: { sk1s: sk1, field: 'trashsk' },
            onCompleted: () => {
              dispatch(setSelectedThreadTrash(true))
              getThreads()
              dispatch(setShowInboxNotification(true))
              dispatch(setSelectedThread({ id: '', name: '' }))
            },
          }

          if (THREAD_IS_TRASH) removeThreadFromTrash(MUTATION_OBJECT)
          else addThreadToTrash(MUTATION_OBJECT)

          onButtonClick()
        },
      },

      attachments: {
        click: () => {
          setIsAttachmentsModalOpen(true)
          onButtonClick()
        },
      },

      contact: {
        click: () => {
          setIsContactsModalOpen(true)
          onButtonClick()
        },
      },
    }

    const isRightRailMobile = useIsRightRailMobile()
    useEffect(() => {
      if (!isRightRailMobile) setIsAttachmentsModalOpen(false)
      if (!isRightRailMobile) setIsContactsModalOpen(false)
    }, [isRightRailMobile])

    return (
      <TransitionContainer
        show={show}
        className={`${className} flex flex-col p-2 !w-[200px]`}
        as="ul"
        transformOrigin="top right"
        unmount={false}
        ref={menuRef}
      >
        {/* Temporarily removing: https://app.asana.com/0/0/1204162713457349/f */}
        {/* <MenuButton mavIconVariant="bell">Notifications</MenuButton> */}

        {/* <ExportButtonWithMenu
          onPdfClick={buttonHandlers.onPdfClick}
          onCsvClick={buttonHandlers.onCsvClick}
        >
          Export
        </ExportButtonWithMenu> */}

        {/* Not present in design: https://tinyurl.com/3t57fpz6 */}
        {/* <MenuButton ccIconVariant="paperClip">Attachment history</MenuButton> */}

        <MenuButton
          mavIconVariant="circleExclamation"
          onClick={buttons.spam.click}
          data-testid={selectedThreadSpam ? 'Remove from spam' : 'Mark as spam'}
        >
          {selectedThreadSpam ? 'Remove from spam' : 'Mark as spam'}
        </MenuButton>

        <MenuButton
          mavIconVariant="altTrash"
          onClick={buttons.trash.click}
          data-testid={selectedThreadTrash ? 'Remove from trash' : 'Move to trash'}
        >
          {selectedThreadTrash ? 'Remove from trash' : 'Move to trash'}
        </MenuButton>

        {isRightRailMobile && (
          <MenuButton
            ccIconVariant="paperClip"
            onClick={buttons.attachments.click}
            data-testid="Attachments"
          >
            Attachments
          </MenuButton>
        )}

        {isRightRailMobile && (
          <MenuButton
            mavIconVariant="user"
            onClick={buttons.contact.click}
            data-testid="Contact"
          >
            Contact
          </MenuButton>
        )}

        {/* ONLY VISIBLE ON MOBILE */}
        <MobileSharedMediaModal
          show={isAttachmentsModalOpen}
          handleCloseModal={() => setIsAttachmentsModalOpen(false)}
        />

        {/* ONLY VISIBLE ON MOBILE */}
        <MobileContactCardModal
          show={isContactsModalOpen}
          closeModal={() => setIsContactsModalOpen(false)}
        />
      </TransitionContainer>
    )
  },
)
